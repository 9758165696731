import {
  type CompanySurvey,
  type OnboardingStep,
  type PerformanceReviewCycle,
  type Prisma,
  type User,
} from "@prisma/client";
import Segment from "analytics-node";
import { promisify } from "bluebird";
import cookie from "cookie";
import { IncomingMessage } from "http";
import { type JsonObject } from "type-fest";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import {
  type CompanyForSegment,
  FIGURES,
  mapSegmentGroupTraits,
  mapSegmentUser,
  mapSegmentUserTraits,
  type UserForSegment,
} from "~/lib/external/segment/context";
import { isNil } from "~/lib/lodash";
import { logWarn } from "~/lib/logger";
import { fireAndForget } from "~/lib/utils";
import { type NullableAuthenticatedUser } from "~/services/auth/fetchAuthenticatedUser";
import packageJson from "../../../../../package.json";

export const makeSegment = () => {
  return new Segment(config.segment.writeKey, {
    flushAt: config.app.debug ? 1 : undefined,
  });
};

const trackingEnabled = !config.app.isLocal;

export const trackServerEvent = async (ctx: AppContext, options: Parameters<Segment["track"]>[0]) => {
  if (!trackingEnabled) {
    return;
  }

  if (!ctx.segment) {
    logWarn(ctx, "[segment] Could not track event because segment is not available");
    return;
  }

  if (!options.userId && !options.anonymousId) {
    logWarn(ctx, "[segment] Could not track event because there is no userId or anonymousId", { options });
    return;
  }

  if (ctx instanceof IncomingMessage) {
    options.context = {
      ...options.context,
      figuresLocation: ctx.headers["figures-location"],
    };
  }

  const track = promisify(ctx.segment.track, { context: ctx.segment });

  await fireAndForget(track(options));
};

export const mapServerContext = (ctx: AppContext, user?: NullableAuthenticatedUser, overrides?: JsonObject) => {
  return {
    ...(user && { userId: user.id }),
    ...(!user && { anonymousId: extractAnonymousIdCookie(ctx) ?? "anonymous" }),
    ...(ctx.impersonation?.user && { impersonationById: ctx.impersonation.user.impersonatedBy.id }),

    context: {
      appVersion: packageJson.version,
      ...mapSegmentUser(user),
      ...overrides,
      ...(user && { groupId: user.isSuperAdmin ? FIGURES.id : user.companyId }),
    },
  };
};

const extractAnonymousIdCookie = (ctx: AppContext) => {
  if (!(ctx instanceof IncomingMessage)) {
    return null;
  }

  if (!ctx.headers.cookie) {
    return null;
  }

  return cookie.parse(ctx.headers.cookie).ajs_anonymous_id ?? null;
};

export const updateSegmentUser = async (ctx: AppContext, user: UserForSegment) => {
  if (!trackingEnabled) {
    return;
  }

  const identify = promisify(ctx.segment.identify, { context: ctx.segment });
  const group = promisify(ctx.segment.group, { context: ctx.segment });

  await Promise.all([
    identify({
      userId: user.id,
      ...(ctx.impersonation?.user && { impersonationById: ctx.impersonation.user.impersonatedBy.id }),
      traits: mapSegmentUserTraits(user, { impersonatingCompany: false }),
    }),
    group({
      userId: user.id,
      ...(ctx.impersonation?.user && { impersonationById: ctx.impersonation.user.impersonatedBy.id }),
      groupId: user.companyId,
      traits: mapSegmentGroupTraits(user, { impersonatingCompany: false }),
    }),
  ]);
};

type CompanySurveyForIntercom = {
  updatedAt: CompanySurvey["updatedAt"];
  collectedAt: CompanySurvey["collectedAt"];
  lastFundingRound: CompanySurvey["lastFundingRound"];
  totalFunding: CompanySurvey["totalFunding"];
} | null;

export const updateSegmentGroup = async (
  ctx: AppContext,
  company: CompanyForSegment & {
    users: Array<Pick<User, "id">>;
    performanceReviewCycles: Pick<PerformanceReviewCycle, "id">[];
  },
  traits: {
    companySurvey?: CompanySurveyForIntercom;
    onboardingStep?: OnboardingStep;
    activeFlagsCount?: number;
    subscriptions?: {
      CAN_ACCESS_SALARY_BANDS?: boolean;
      CAN_ACCESS_COMPENSATION_PLANNING?: boolean;
      CAN_ACCESS_BENCHMARK?: boolean;
      CAN_ACCESS_COMPENSATION_REVIEW: boolean;
    };
  } = {}
) => {
  const userId = company.users[0]?.id;
  if (!userId) {
    logWarn(ctx, "[segment] Could not update group for company because there is no admin user", { company });

    return;
  }

  const group = promisify(ctx.segment.group, { context: ctx.segment });

  const { onboardingStep, activeFlagsCount } = traits;

  const payload = {
    userId,
    groupId: company.id,
    ...(ctx.impersonation?.user && { impersonationById: ctx.impersonation.user.impersonatedBy.id }),
    traits: {
      isDisabled: !!company.disabledAt,
      surveyCollectedAt: traits.companySurvey?.collectedAt ? traits.companySurvey?.collectedAt.toISOString() : null,
      surveyUpdatedAt: traits.companySurvey?.updatedAt ? traits.companySurvey?.updatedAt.toISOString() : null,
      surveyLastFundingRound: traits.companySurvey?.lastFundingRound,
      surveyTotalFunding: traits.companySurvey?.totalFunding,
      hasPerformanceReviewCycle: company.performanceReviewCycles.length > 0,
      ...(onboardingStep && {
        lastOnboardingStepCompleted: onboardingStep,
        lastOnboardingStepCompletedAt: new Date().toISOString(),
      }),
      ...(!isNil(activeFlagsCount) && {
        activeFlagsCount,
      }),
      ...(traits.subscriptions && {
        subscriptions: traits.subscriptions,
      }),
    },
  };

  await group(payload);
};

export const companySelectForSegment = {
  id: true,
  name: true,
  type: true,
  demo: true,
  excludedFromDataset: true,
  users: {
    select: {
      id: true,
    },
  },
  disabledAt: true,
  performanceReviewCycles: {
    select: {
      id: true,
    },
  },
} satisfies Prisma.CompanySelect;

export const userSelectForSegment = {
  id: true,
  companyId: true,
  email: true,
  isSuperAdmin: true,
  onboardingRole: true,
  blockedAt: true,
  company: { select: companySelectForSegment },
  permissions: { select: { role: true } },
} satisfies Prisma.UserSelect;

export const fetchCompanyForSegment = async (ctx: AppContext, params: { companyId: number }) => {
  return ctx.prisma.company.findUniqueOrThrow({
    where: { id: params.companyId },
    select: companySelectForSegment,
  });
};
