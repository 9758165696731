import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import type React from "react";
import { useEffect, useState } from "react";
import packageJson from "~/../package.json";
import { config } from "~/config";
import { useSession } from "~/hooks/useSession";
import { datadogEnv } from "~/lib/datadog/datadogEnv";
import { type AuthenticatedUser } from "~/lib/session";
import { isClient, isServer } from "~/lib/ssr";
import { getFiguresCompanyId } from "~/lib/utils";

export const configureDatadogBrowserLogs = (user: AuthenticatedUser): void => {
  if (config.app.isLocal) {
    return;
  }

  if (isClient) {
    datadogRum.init({
      applicationId: "134057f5-2b8d-40ff-8a73-4c9c104a8a3f",
      clientToken: config.datadog.clientToken,
      site: "datadoghq.eu",
      service: `${packageJson.name}.app`,
      env: datadogEnv,
      version: packageJson.version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
      allowedTracingUrls: [`${config.app.url}/api`],
    });

    const isFiguresUser = user.companyId === getFiguresCompanyId() || user.isSuperAdmin;

    datadogRum.setUser({
      id: user.id.toString(),
      name: user.id.toString(),
      email: isFiguresUser ? "csm" : "client",
      userId: user.id,
      companyId: user.company.id,
      isManager: user.permissions.isManager,
      role: user.permissions.role,
      isFiguresUser,
    });
  }

  datadogLogs.init({
    version: packageJson.version,
    clientToken: config.datadog.clientToken,
    site: "datadoghq.eu",
    env: datadogEnv,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    service: `${packageJson.name}.${isServer ? "next" : "app"}`,
    silentMultipleInit: true,
  });
};

export const DatadogBrowserLogs: React.FC = () => {
  const { user } = useSession();
  const [ddAlreadySetup, setDdAlreadySetup] = useState(false);

  useEffect(() => {
    if (user && !ddAlreadySetup) {
      configureDatadogBrowserLogs(user);
      setDdAlreadySetup(true);
    }
  }, [user, ddAlreadySetup]);

  return null;
};
