import { type Currency, UserLocale } from "@prisma/client";
import { value as valueFn } from "~/components/helpers";
import { useFeatureFlags } from "~/hooks/useFeatureFlags";
import { useLocale } from "~/hooks/useLocale";
import { type SalaryPeriodConfig, useSalaryPeriod } from "~/hooks/useSalaryPeriod";
import { roundTo } from "~/lib/math";

type MoneyFormatOptions = {
  roundTo?: number;
  salaryPeriodConfig?: SalaryPeriodConfig;
};

export type Money = {
  format: (amount: number, options?: MoneyFormatOptions) => string;
};

export const PlaceholderCurrency: Currency = {
  id: 1,
  code: "EUR",
  symbol: "€",
  name: "Euro",
  euroExchangeRate: 1,
  decimals: 2,
  createdAt: new Date(),
  updatedAt: new Date(),
  translations: { [UserLocale.FR]: "Euro", [UserLocale.EN]: "Euro" },
};

export const useMoney = (currency: Pick<Currency, "code" | "decimals">): Money => {
  const { locale } = useLocale();
  const { convertSalaryToFrequency } = useSalaryPeriod();

  const { CAN_SEE_COMPENSATION_REVIEW_DECIMAL_AMOUNTS } = useFeatureFlags();

  return {
    format: (amount, options) => {
      const fractionDigits = valueFn(() => {
        if (options?.roundTo) {
          if (options.roundTo > 1) return 0;

          return -Math.log10(options.roundTo);
        }

        if (CAN_SEE_COMPENSATION_REVIEW_DECIMAL_AMOUNTS) {
          return currency.decimals;
        }

        return 0;
      });

      const intl = new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currency.code,
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      });

      const value = options?.salaryPeriodConfig ? convertSalaryToFrequency(amount, options.salaryPeriodConfig) : amount;

      const roundedValue =
        options?.roundTo && options.roundTo > 1 ? roundTo(value / 100, options.roundTo) : value / 100;

      return intl.format(roundedValue);
    },
  };
};
