import {
  CompensationReviewBudgetDefinitionMethod,
  CompensationReviewBudgetSalaryMassMeasure,
  type Prisma,
} from "@prisma/client";
import { mapSeries } from "bluebird";
import { match } from "ts-pattern";
import { ensure } from "~/lib/ensure";
import { compact, isNil } from "~/lib/lodash";
import { convertCurrency } from "~/lib/money";
import { type UpdateBudgetDefinitionInput } from "~/pages/api/compensation-review/campaigns/update-budget-definition";
import { computeSalaryMass } from "~/services/compensation-review/campaigns/analytics/computeSalaryMass";
import { type CompensationReviewCampaignBudget } from "~/services/compensation-review/campaigns/fetchCampaign";
import { generateEmployeesGroups } from "~/services/compensation-review/campaigns/generateEmployeesGroups";
import { type CompensationReviewCampaignContext } from "~/services/compensation-review/compensationReviewContext";
import { getCompensationReviewBudget } from "~/services/compensation-review/shared/compensationReviewBudget";
import { sendUpdateCampaignBudgetsJob } from "~/workers/updateCampaignBudgets";

export const updateBudgetDefinition = async (
  ctx: CompensationReviewCampaignContext,
  input: UpdateBudgetDefinitionInput
) => {
  ensure(() => ctx.permissions.canConfigure);

  await ctx.prisma.compensationReviewBudget.update({
    where: { id: input.budgetId },
    data: {
      budgetingMethod: input.budgetingMethod,
      prorationStartDate: input.prorationStartDate,

      ...(!isNil(input.amount) && { amount: input.amount }),

      budgetDefinitionMethod: input.budgetDefinitionMethod,
      salaryMassMeasure: input.salaryMassMeasure,
      distributionKey: input.distributionKey,

      adjustmentCriteria: null,
      recommendationsAllocation: null,
      skippedPerformanceRatingIds: [],
      skippedPositionings: [],
      subBudgets: {
        deleteMany: {},
      },
    },
  });

  await mapSeries(input.subBudgets, (subBudget) => {
    if (!subBudget.amount) return;

    return ctx.prisma.compensationReviewSubBudget.create({
      data: {
        campaignId: ctx.campaign.id,
        budgetId: input.budgetId,
        name: subBudget.name,
        amount: subBudget.amount,
      },
    });
  });

  if (
    input.budgetDefinitionMethod === CompensationReviewBudgetDefinitionMethod.PROPORTIONAL_ALLOCATION &&
    !!input.amount
  ) {
    await ctx.prisma.compensationReviewSubBudget.create({
      data: {
        campaignId: ctx.campaign.id,
        budgetId: input.budgetId,
        name: "Default",
        amount: input.amount,
      },
    });
  }

  if (!!input.amount) {
    await sendUpdateCampaignBudgetsJob(ctx, {
      companyId: ctx.companyId,
      campaignId: ctx.campaign.id,
      budgetId: input.budgetId,
    });
  }
};

export const updateBudgets = async (ctx: CompensationReviewCampaignContext, budgetId: number) => {
  const budget = getCompensationReviewBudget(ctx, budgetId);

  if (budget.distributionKey) {
    const groups = await generateEmployeesGroups(ctx, {
      budgetId,
      groupBy: budget.distributionKey,
    });

    await mapSeries(groups.items, async (group) => {
      const subBudget = budget.subBudgets.find((subBudget) => subBudget.name === group.name);

      if (!subBudget) return;

      await updateEmployeesBudget(ctx, {
        budget,
        amount: subBudget.amount,
        where: group.where,
      });
    });
  } else if (budget.amount) {
    await updateEmployeesBudget(ctx, { budget, amount: budget.amount });
  }

  const totalBudget = await ctx.prisma.compensationReviewBudgetEligibility.aggregate({
    _sum: { convertedBudgetedAmount: true },
    where: {
      campaignId: ctx.campaign.id,
      budgetId: budget.id,
    },
  });

  await ctx.prisma.compensationReviewBudget.update({
    where: { id: budget.id },
    data: {
      amount: totalBudget._sum?.convertedBudgetedAmount ?? null,
    },
  });
};

const updateEmployeesBudget = async (
  ctx: CompensationReviewCampaignContext,
  params: {
    budget: CompensationReviewCampaignBudget;
    amount: number | null;
    where?: Prisma.CompensationReviewEmployeeWhereInput;
  }
) => {
  const where = {
    AND: compact([
      params.where,
      {
        campaignId: ctx.campaign.id,
        eligibilities: { some: { budgetId: params.budget.id } },
      },
    ]),
  } satisfies Prisma.CompensationReviewEmployeeWhereInput;

  const employees = await ctx.prisma.compensationReviewEmployee.findMany({
    where,
    select: {
      id: true,
      convertedOnTargetEarnings: true,
      currency: true,
      eligibilities: {
        where: { budgetId: params.budget.id },
      },
    },
  });

  const salaryMass = await computeSalaryMass(ctx, { where });
  const salaryMassAmount = salaryMass[salaryMassMeasureToMeasure(params.budget.salaryMassMeasure)];

  await mapSeries(employees, async (employee) => {
    const eligibility = employee.eligibilities.find((eligibility) => eligibility.budgetId === params.budget.id);
    if (!eligibility) return;

    const salaryMassRatio = employee.convertedOnTargetEarnings / salaryMassAmount;
    const convertedBudgetedAmount = params.amount ? Math.round(salaryMassRatio * params.amount) : null;
    const budgetedAmount = convertedBudgetedAmount
      ? convertCurrency(convertedBudgetedAmount, ctx.campaign.currency, employee.currency)
      : null;

    await ctx.prisma.compensationReviewBudgetEligibility.update({
      where: { id: eligibility.id },
      data: {
        budgetedAmount,
        convertedBudgetedAmount,
      },
    });
  });
};

export const salaryMassMeasureToMeasure = (value?: CompensationReviewBudgetSalaryMassMeasure | null) =>
  match(value)
    .with(CompensationReviewBudgetSalaryMassMeasure.BASE_SALARY, () => "baseSalary" as const)
    .with(CompensationReviewBudgetSalaryMassMeasure.ON_TARGET_EARNINGS, () => "onTargetEarnings" as const)
    .otherwise(() => "onTargetEarnings" as const);
