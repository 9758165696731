import { SalaryGridMeasure } from "@prisma/client";
import { mapSeries } from "bluebird";
import { convertCurrency } from "~/lib/money";
import { type CompensationReviewCampaignContext } from "~/services/compensation-review/compensationReviewContext";
import { getCompensationReviewBudget } from "~/services/compensation-review/shared/compensationReviewBudget";
import { computeDateProration } from "~/services/compensation-review/shared/computeDateProration";
import { computeCompaRatio } from "~/services/salary-bands/benchmark/compaRatio";
import { computeRangePenetration } from "~/services/salary-bands/benchmark/rangePenetration";

export const refreshConvertedAmounts = async (ctx: CompensationReviewCampaignContext) => {
  const employees = await ctx.prisma.compensationReviewEmployee.findMany({
    where: { campaignId: ctx.campaign.id },
    select: {
      id: true,
      fteCoefficient: true,
      baseSalary: true,
      variablePay: true,
      otherBonus: true,
      onTargetEarnings: true,
      totalCash: true,
      effectiveDate: true,
      currency: { select: { euroExchangeRate: true } },
      externalEmployee: {
        select: {
          id: true,
          liveSalaryRangeEmployee: {
            select: {
              range: {
                select: {
                  midpoint: true,
                  min: true,
                  max: true,
                  band: {
                    select: {
                      currency: true,
                      measure: true,
                    },
                  },
                },
              },
              orderingCompaRatio: true,
              orderingRangePenetration: true,
            },
          },
        },
      },
      eligibilities: {
        select: {
          id: true,
          budgetedAmount: true,
        },
      },
      adjustments: {
        select: {
          id: true,
          submittedAmount: true,
          recommendedAmount: true,
          budgetId: true,
        },
      },
    },
  });

  await mapSeries(employees, async (employee) => {
    const convertAmount = <Amount extends number | null, ReturnType = Amount extends number ? number : null>(
      amount: Amount
    ): ReturnType => {
      if (amount === null) return null as ReturnType;

      return convertCurrency(amount, employee.currency, ctx.campaign.currency) as ReturnType;
    };

    const salary =
      employee.externalEmployee.liveSalaryRangeEmployee?.range.band.measure === SalaryGridMeasure.BASE_SALARY
        ? employee.baseSalary
        : employee.onTargetEarnings;

    // salary is the "real" salary so we need to divide it by the coef to get the FTE one
    const fteSalary = salary / (employee.fteCoefficient ?? 1);

    const payload = {
      convertedBaseSalary: convertAmount(employee.baseSalary),
      convertedVariablePay: convertAmount(employee.variablePay),
      convertedOnTargetEarnings: convertAmount(employee.onTargetEarnings),
      convertedOtherBonus: convertAmount(employee.otherBonus),
      convertedTotalCash: convertAmount(employee.totalCash),

      compaRatio: employee.externalEmployee.liveSalaryRangeEmployee?.range.midpoint
        ? computeCompaRatio({
            amount: convertAmount(fteSalary),
            midpoint: convertCurrency(
              employee.externalEmployee.liveSalaryRangeEmployee.range.midpoint,
              employee.externalEmployee.liveSalaryRangeEmployee.range.band.currency,
              ctx.campaign.currency
            ),
          })
        : null,

      rangePenetration:
        employee.externalEmployee.liveSalaryRangeEmployee?.range.min &&
        employee.externalEmployee.liveSalaryRangeEmployee?.range.max
          ? computeRangePenetration({
              amount: convertAmount(fteSalary),
              min: convertCurrency(
                employee.externalEmployee.liveSalaryRangeEmployee.range.min,
                employee.externalEmployee.liveSalaryRangeEmployee.range.band.currency,
                ctx.campaign.currency
              ),
              max: convertCurrency(
                employee.externalEmployee.liveSalaryRangeEmployee.range.max,
                employee.externalEmployee.liveSalaryRangeEmployee.range.band.currency,
                ctx.campaign.currency
              ),
            })
          : null,
    };

    await ctx.prisma.compensationReviewEmployee.update({
      where: { id: employee.id },
      data: payload,
    });

    await mapSeries(employee.eligibilities, async (eligibility) => {
      await ctx.prisma.compensationReviewBudgetEligibility.update({
        where: { id: eligibility.id },
        data: {
          convertedBudgetedAmount: convertAmount(eligibility.budgetedAmount),
        },
      });
    });

    await mapSeries(employee.adjustments, async (adjustment) => {
      const budget = getCompensationReviewBudget(ctx, adjustment.budgetId);

      const convertedSubmittedAmount = convertAmount(adjustment.submittedAmount);
      const { proratedAmount: proratedSubmittedAmount } = computeDateProration({
        amount: convertedSubmittedAmount,
        prorationStartDate: budget.prorationStartDate,
        effectiveDate: employee.effectiveDate,
      });

      const convertedRecommendedAmount = convertAmount(adjustment.recommendedAmount);
      const { proratedAmount: proratedRecommendedAmount } = computeDateProration({
        amount: convertedRecommendedAmount,
        prorationStartDate: budget.prorationStartDate,
        effectiveDate: employee.effectiveDate,
      });

      const payload = {
        convertedRecommendedAmount,
        proratedRecommendedAmount,
        convertedSubmittedAmount,
        proratedSubmittedAmount,
      };

      await ctx.prisma.compensationReviewAdjustment.update({
        where: { id: adjustment.id },
        data: payload,
      });
    });
  });
};
