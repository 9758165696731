import { type Prisma, type QueueJobStatus } from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { type QueueJobName } from "~/lib/queue/queueJobName";

export const updateQueueJobStatus = async (
  ctx: AppContext,
  params: {
    companyId: number;
    name: QueueJobName;
    singletonKey: string;
    status: QueueJobStatus;
    externalJobId: string | null;
    output?: Prisma.InputJsonValue;
  }
) => {
  const { name, singletonKey, status, externalJobId, output, companyId } = params;

  const existingQueueJob = await ctx.prisma.queueJob.findUnique({
    where: {
      companyId,
      name_singletonKey: { name, singletonKey },
    },
  });

  if (existingQueueJob) {
    return ctx.prisma.queueJob.update({
      where: { id: existingQueueJob.id },
      data: { status, externalJobId, output },
    });
  }

  return ctx.prisma.queueJob.create({ data: params });
};
