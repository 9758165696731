import { SalaryGridMeasure } from "@prisma/client";
import { compact } from "~/lib/lodash";
import { type FilterOptionsInput } from "~/services/employee-filter";

export const buildUniqueSalaryRangeEmployeeFilterWhereClauses = (selectedFilterOptions: FilterOptionsInput) => {
  return compact([
    !!selectedFilterOptions?.rangePositionings?.length && {
      range: { band: { isDraft: false } },
      OR: [
        {
          range: { band: { measure: SalaryGridMeasure.BASE_SALARY } },
          baseSalaryRangePositioning: { in: compact(selectedFilterOptions.rangePositionings) },
        },
        {
          range: { band: { measure: SalaryGridMeasure.ON_TARGET_EARNINGS } },
          onTargetEarningsRangePositioning: { in: compact(selectedFilterOptions.rangePositionings) },
        },
      ],
    },

    !!selectedFilterOptions?.compaRatio?.length && {
      range: { band: { isDraft: false } },
      OR: [
        {
          range: { band: { measure: SalaryGridMeasure.BASE_SALARY } },
          baseSalaryCompaRatio: {
            gte: selectedFilterOptions.compaRatio[0],
            lte: selectedFilterOptions.compaRatio[1],
          },
        },
        {
          range: { band: { measure: SalaryGridMeasure.ON_TARGET_EARNINGS } },
          onTargetEarningsCompaRatio: {
            ...(!!selectedFilterOptions.compaRatio[0] && { gte: selectedFilterOptions.compaRatio[0] }),
            ...(!!selectedFilterOptions.compaRatio[1] && { lte: selectedFilterOptions.compaRatio[1] }),
          },
        },
      ],
    },
  ]);
};
