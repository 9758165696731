import type { IncomingMessage } from "http";
import HttpStatus from "http-status-codes";
import type { NextApiResponse } from "next";
import type { Route } from "nextjs-routes";
import { parse } from "url";
import type { ApiRoutePath } from "~/hooks/useApi";
import type { AppContext } from "~/lib/context";
import { logWarn } from "~/lib/logger";

export class AuthenticationError extends Error {
  public userId?: number;
  public redirect?: Route;

  constructor(
    message: string,
    params?: {
      userId?: number;
      redirect?: {
        targetUrl?: string;
        error?: string;
      };
    }
  ) {
    super(message);
    Object.setPrototypeOf(this, AuthenticationError.prototype);

    this.userId = params?.userId;

    if (params?.redirect?.targetUrl) {
      this.redirect = {
        pathname: "/sign-in",
        query: {
          redirect: encodeURIComponent(params.redirect.targetUrl),
          error: params.redirect.error,
        },
      };
    }
  }
}

export class PublicApiAuthenticationError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, AuthenticationError.prototype);
  }
}

export const handleAuthenticationError = (ctx: AppContext, res: NextApiResponse, error: AuthenticationError) => {
  // Ignoring logs for LinkedIn Extension
  const parsedUrl = parse((ctx as IncomingMessage).url as string, true);
  if ((parsedUrl.pathname as ApiRoutePath) !== "/api/internal-partner/market-data-benchmark") {
    logWarn(ctx, "[error] Invalid authentication", { error });
  }

  return res.status(HttpStatus.UNAUTHORIZED).json({ error: error.message });
};
