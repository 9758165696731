import { EmployeeStatus, ExternalEmployeeStatus, type Prisma } from "@prisma/client";
import { match } from "ts-pattern";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { compact, isString } from "~/lib/lodash";
import { logWarn } from "~/lib/logger";
import {
  buildPaginationResult,
  type OrderParams,
  type PaginationParams,
  prismaPaginationParams,
} from "~/lib/pagination";
import { getId } from "~/lib/utils";
import { type PeopleTab } from "~/pages/people/market-data";
import { externalEmployeeFieldsSelectForDisplay } from "~/services/additional-field/getAllAdditionalFields";
import { type FilterOptionsInput } from "~/services/employee-filter";
import {
  buildExternalEmployeeFilterWhereClauses,
  fetchExternalEmployeeFilterOptions,
} from "~/services/employee-filter/fetchExternalEmployeeFilterOptions";
import { fetchUserExternalEmployeeManageeIds } from "~/services/external-employee/fetchExternalEmployeeManageeIds";
import { selectExternalEmployeeUserPicture } from "~/services/external-employee/selectExternalEmployeeUserForPicture";
import { getAllowedJobFamilyIds } from "~/services/job";
import { BELOW_TARGET_MAX_PERCENTAGE_DIFFERENCE } from "~/services/marketPositioning";
import { getUserRoles } from "~/services/user/permissions/utils/getUserRoles";
import { searchExternalEmployee } from "~/services/users/search";

const salaryRangeEmployeeSelect = {
  id: true,
  externalEmployeeId: true,
  baseSalaryCompaRatio: true,
  onTargetEarningsCompaRatio: true,
  baseSalaryRangePenetration: true,
  onTargetEarningsRangePenetration: true,
  orderingCompaRatio: true,
  orderingRangePenetration: true,
  range: {
    select: {
      id: true,
      level: {
        select: {
          id: true,
          name: true,
          description: true,
        },
      },
      band: {
        select: {
          id: true,
          isDraft: true,
          grid: { select: { id: true, status: true, tiersMode: true } },
          measure: true,
          location: {
            select: {
              id: true,
              name: true,
            },
          },
          job: {
            select: {
              id: true,
              name: true,
              description: true,
            },
          },
        },
      },
    },
  },
} satisfies Prisma.SalaryRangeEmployeeSelect;

const liveEmployeeStatsSelect = {
  comparisonScope: true,
  targetPercentile: true,
  comparedEmployeesCount: true,
  comparedCompaniesCount: true,
  comparedLevels: true,
  baseSalaryDifference: true,
  baseSalaryForPercentile: true,
  baseSalaryPercentageDifference: true,
  baseSalaryDataQuality: true,
  onTargetEarningsDifference: true,
  onTargetEarningsForPercentile: true,
  onTargetEarningsPercentageDifference: true,
  onTargetEarningsDataQuality: true,
  totalCashDifference: true,
  totalCashForPercentile: true,
  totalCashPercentageDifference: true,
  totalCashDataQuality: true,
  comparedLocations: { select: { name: true } },
} satisfies Prisma.EmployeeStatsSelect;

const employeeLocationSelect = {
  id: true,
  name: true,
  isRemote: true,
  country: {
    select: {
      id: true,
      name: true,
      alpha2: true,
    },
  },
} satisfies Prisma.EmployeeLocationSelect;

const currencySelect = {
  id: true,
  name: true,
  code: true,
  symbol: true,
  euroExchangeRate: true,
  decimals: true,
  createdAt: true,
  updatedAt: true,
  translations: true,
} satisfies Prisma.CurrencySelect;

const mappedEmployeeSelect = {
  id: true,
  status: true,
  baseSalary: true,
  fixedBonus: true,
  onTargetBonus: true,
  onTargetBonusPercentage: true,
  fixedBonusPercentage: true,
  employeeDataValidationFlags: true,
  externalJobTitle: true,
  externalLevel: true,
  level: true,
  gender: true,
  updateReason: true,
  birthDate: true,
  hireDate: true,
  createdAt: true,
  locationId: true,
  source: true,
  currency: { select: currencySelect },
  location: { select: employeeLocationSelect },
  job: { select: { id: true, name: true } },
  liveEmployeeStats: { select: liveEmployeeStatsSelect },
} satisfies Prisma.EmployeeSelect;

const remunerationsItemsSelect = {
  remunerationItems: {
    orderBy: [
      {
        date: {
          sort: "desc",
          nulls: "last",
        },
      },
      { amount: "desc" },
    ],
    select: {
      amount: true,
      date: true,
      status: true,
      nature: {
        select: {
          name: true,
          mappedType: true,
        },
      },
    },
  },
} satisfies Prisma.ExternalEmployeeSelect;

//Standard
const peopleDashboardSelect = {
  ...selectExternalEmployeeUserPicture,
  id: true,
  status: true,
  employeeNumber: true,
  firstName: true,
  lastName: true,
  externalId: true,
  job: {
    select: {
      name: true,
    },
  },
  level: {
    select: {
      name: true,
    },
  },
  location: {
    select: {
      name: true,
      country: {
        select: {
          name: true,
          alpha2: true,
        },
      },
    },
  },
};

export const buildFetchExternalEmployeesWhereClause = async (
  ctx: AppContext,
  params: { query?: string | null; tab?: PeopleTab | null }
) => {
  const user = getRequiredUser(ctx);

  const allowedCountriesWhere = {
    countryId: { in: user.permissions.allowedCountries.map(getId) },
  } satisfies Prisma.EmployeeLocationWhereInput;

  const allowedJobFamilyIds = getAllowedJobFamilyIds(user) ?? [];
  const hasAtLeastOneRestrictionSetUp =
    allowedJobFamilyIds.length > 0 ||
    user.permissions.allowedLevels.length > 0 ||
    user.permissions.allowedCountries.length > 0;

  const where: Prisma.ExternalEmployeeWhereInput = {
    companyId: user.companyId,
    status: { not: ExternalEmployeeStatus.SKIPPED },

    ...(!user.isSuperAdmin &&
      hasAtLeastOneRestrictionSetUp && {
        OR: [
          {
            mappedEmployee: null,
            ...(user.permissions.allowedLevels.length && {
              level: { mappedLevel: { in: user.permissions.allowedLevels } },
            }),
            ...(allowedJobFamilyIds.length && {
              job: { mappedJob: { familyId: { in: allowedJobFamilyIds } } },
            }),
            ...(user.permissions.allowedCountries.length && {
              location: {
                OR: [allowedCountriesWhere, { mappedLocation: allowedCountriesWhere }],
              },
            }),
          },
          {
            mappedEmployee: {
              ...(user.permissions.allowedLevels.length && { level: { in: user.permissions.allowedLevels } }),
              ...(allowedJobFamilyIds.length && {
                job: { familyId: { in: allowedJobFamilyIds } },
              }),
              ...(user.permissions.allowedCountries.length && {
                location: allowedCountriesWhere,
              }),
            },
          },
        ],
      }),
  };

  if (
    (params.tab === "reports" && getUserRoles(user).isManager) ||
    (getUserRoles(user).isEmployee && getUserRoles(user).isManager)
  ) {
    const externalEmployeeManageeIds = await fetchUserExternalEmployeeManageeIds(ctx, { user });

    if (externalEmployeeManageeIds) {
      where.id = { in: externalEmployeeManageeIds };
    }
  }

  if (isString(params.query)) {
    where.AND = searchExternalEmployee(params.query);
  }

  return where;
};

const buildFetchExternalEmployeesOrderByClause = (
  ctx: AppContext,
  params: {
    order?: OrderParams;
  }
) => {
  const column = params.order?.column ?? null;
  const direction = params.order?.direction ?? "asc";
  const directionNullsLast = { sort: direction, nulls: "last" } as const;

  return match<string | null, Prisma.Enumerable<Prisma.ExternalEmployeeOrderByWithRelationInput>>(column)
    .with("business-unit", () => ({ businessUnit: direction }))
    .with("employee-number", () => ({ employeeNumber: direction }))
    .with("name", () => [{ firstName: directionNullsLast }, { lastName: directionNullsLast }])
    .with("job", () => [{ mappedEmployee: { job: { name: direction } } }, { job: { name: direction } }])
    .with("level", () => [{ mappedEmployee: { level: direction } }, { level: { name: direction } }])
    .with("location", () => [{ mappedEmployee: { location: { name: direction } } }, { location: { name: direction } }])
    .with("gender", () => ({ gender: directionNullsLast }))
    .with("hire-date", () => ({ hireDate: direction }))
    .with("manager", () => [
      { manager: { firstName: directionNullsLast } },
      { manager: { lastName: directionNullsLast } },
    ])
    .with("compa-ratio", () => ({ liveSalaryRangeEmployee: { orderingCompaRatio: directionNullsLast } }))
    .with("range-penetration", () => ({ liveSalaryRangeEmployee: { orderingRangePenetration: directionNullsLast } }))
    .with("base-salary", () => ({ mappedEmployee: { baseSalary: direction } }))
    .with("on-target-bonus", () => ({ mappedEmployee: { onTargetBonus: directionNullsLast } }))
    .with("fixed-bonus", () => ({ mappedEmployee: { fixedBonus: directionNullsLast } }))
    .with("total-cash", () => ({ mappedEmployee: { liveEmployeeStats: { totalCash: direction } } }))
    .with("on-target-earnings", () => ({ mappedEmployee: { liveEmployeeStats: { onTargetEarnings: direction } } }))
    .with("target-percentile", () => ({ mappedEmployee: { liveEmployeeStats: { targetPercentile: direction } } }))
    .with("total-cash-data-quality", () => ({
      mappedEmployee: { liveEmployeeStats: { totalCashDataQuality: directionNullsLast } },
    }))
    .with("base-salary-data-quality", () => ({
      mappedEmployee: { liveEmployeeStats: { baseSalaryDataQuality: directionNullsLast } },
    }))
    .with("on-target-earnings-data-quality", () => ({
      mappedEmployee: { liveEmployeeStats: { onTargetEarningsDataQuality: directionNullsLast } },
    }))
    .with("total-cash-market-percentile", () => ({
      mappedEmployee: { liveEmployeeStats: { totalCashForPercentile: directionNullsLast } },
    }))
    .with("total-cash-market-percentile-difference", () => ({
      mappedEmployee: { liveEmployeeStats: { totalCashDifference: directionNullsLast } },
    }))
    .with("total-cash-market-percentile-percentage-difference", () => ({
      mappedEmployee: { liveEmployeeStats: { totalCashPercentageDifference: directionNullsLast } },
    }))
    .with("base-salary-market-percentile", () => ({
      mappedEmployee: { liveEmployeeStats: { baseSalaryForPercentile: directionNullsLast } },
    }))
    .with("base-salary-market-percentile-difference", () => ({
      mappedEmployee: { liveEmployeeStats: { baseSalaryDifference: directionNullsLast } },
    }))
    .with("base-salary-market-percentile-percentage-difference", () => ({
      mappedEmployee: { liveEmployeeStats: { baseSalaryPercentageDifference: directionNullsLast } },
    }))
    .with("on-target-earnings-market-percentile", () => ({
      mappedEmployee: { liveEmployeeStats: { onTargetEarningsForPercentile: directionNullsLast } },
    }))
    .with("on-target-earnings-market-percentile-difference", () => ({
      mappedEmployee: { liveEmployeeStats: { onTargetEarningsDifference: directionNullsLast } },
    }))
    .with("on-target-earnings-market-percentile-percentage-difference", () => ({
      mappedEmployee: { liveEmployeeStats: { onTargetEarningsPercentageDifference: directionNullsLast } },
    }))
    .with("performance-review-rating", () => ({
      performanceReviewRating: { position: directionNullsLast },
    }))
    .otherwise((column) => {
      if (column) {
        logWarn(ctx, "[warn] Unhandled employee order column", { column });
      }

      return { employeeNumber: direction };
    });
};

const selectForStandardsColumns = {
  ...externalEmployeeFieldsSelectForDisplay,
  ...remunerationsItemsSelect,
  ...peopleDashboardSelect,
} satisfies Prisma.ExternalEmployeeSelect;

const selectForMarketDataColumns = {
  mappedEmployee: {
    select: {
      ...mappedEmployeeSelect,
    },
  },
} satisfies Prisma.ExternalEmployeeSelect;

const selectForSalaryBandsColumns = {
  liveSalaryRangeEmployee: { select: salaryRangeEmployeeSelect },
} satisfies Prisma.ExternalEmployeeSelect;

export const fetchExternalEmployeesForPeopleDashboard = async (
  ctx: AppContext,
  params: {
    query?: string | null;
    tab?: PeopleTab | null;
    pagination?: PaginationParams;
    order?: OrderParams;
    filters?: FilterOptionsInput;
    onlyBelowTarget?: boolean;
    isMarketDataColumnsVisible: boolean;
    isSalaryBandsColumnsVisible: boolean;
  }
) => {
  const baseWhere = await buildFetchExternalEmployeesWhereClause(ctx, params);
  const whereFilters = params.filters ? await buildExternalEmployeeFilterWhereClauses(ctx, params.filters) : undefined;
  const orderBy = buildFetchExternalEmployeesOrderByClause(ctx, params);

  const extraWhere = {
    ...(params.onlyBelowTarget && {
      mappedEmployee: {
        liveEmployeeStats: {
          status: EmployeeStatus.LIVE,
          totalCashPercentageDifference: {
            lte: BELOW_TARGET_MAX_PERCENTAGE_DIFFERENCE,
            not: null,
          },
        },
      },
    }),
  };

  const where = {
    AND: compact([baseWhere, whereFilters, extraWhere]),
  };

  const externalEmployeeSelectForPeopleDashboard = {
    ...selectForStandardsColumns,
    ...selectForMarketDataColumns,
    ...selectForSalaryBandsColumns,
    currency: { select: currencySelect },
  } satisfies Prisma.ExternalEmployeeSelect;

  const externalEmployeesPromise = ctx.prisma.externalEmployee.findMany({
    where,
    orderBy,
    select: {
      ...externalEmployeeSelectForPeopleDashboard,
      ...(params.isMarketDataColumnsVisible && {
        mappedEmployee: {
          select: {
            ...mappedEmployeeSelect,
          },
        },
      }),
      ...(params.isSalaryBandsColumnsVisible && {
        liveSalaryRangeEmployee: {
          select: {
            ...salaryRangeEmployeeSelect,
          },
        },
      }),
    },

    ...(params.pagination && {
      ...prismaPaginationParams(params.pagination),
    }),
  });

  const countPromise = ctx.prisma.externalEmployee.count({
    where,
    orderBy,
  });

  const [items, count] = await Promise.all([externalEmployeesPromise, countPromise]);

  const filterOptions = await fetchExternalEmployeeFilterOptions(ctx);

  return buildPaginationResult({
    items,
    count,
    pagination: params.pagination,
    meta: {
      filterOptions,
    },
  });
};

export type FetchExternalEmployeesForPeopleDashboardResult = AsyncReturnType<
  typeof fetchExternalEmployeesForPeopleDashboard
>;

export type ExternalEmployeeForPeopleDashboard = FetchExternalEmployeesForPeopleDashboardResult["items"][number];
