import {
  CompensationReviewCampaignStatus,
  CompensationReviewCompensationItem,
  CompensationReviewConfigurationStep,
  CompensationReviewSalaryDisplay,
} from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { ensure } from "~/lib/ensure";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { transaction } from "~/lib/transaction";
import { refreshCampaignCurrencies } from "~/services/compensation-review/campaigns/admin/refreshCampaignCurrencies";
import { sendSynchroniseCompensationReviewCampaignEmployeesJob } from "~/workers/syncCompensationReviewCampaignEmployees";

export const DEFAULT_MAX_REVIEWERS_COUNT = 2;

export const MAX_MAX_REVIEWERS_COUNT = 10;

export const createCampaign = async (ctx: AppContext) => {
  ensure(() => ctx.globalPermissionsContext.canCreateCompensationReviewCampaign);

  const user = getRequiredUser(ctx);

  const campaign = await transaction(ctx, async (ctx) => {
    const campaignsCount = await ctx.prisma.compensationReviewCampaign.count({
      where: { companyId: user.companyId },
    });

    const campaign = await ctx.prisma.compensationReviewCampaign.create({
      data: {
        companyId: user.companyId,
        name: ctx.t("services.compensation-review.campaigns.admin.createCampaign.default-campaign-name", {
          number: campaignsCount + 1,
        }),
        status: CompensationReviewCampaignStatus.CONFIGURATION,
        configurationStep: CompensationReviewConfigurationStep.CAMPAIGN_DETAILS,
        validatedConfigurationSteps: [],
        requiresSandboxReset: false,
        displaySalaryPeriodSelector: getDisplaySalaryPeriodSelector(ctx),
        maxReviewersCount: DEFAULT_MAX_REVIEWERS_COUNT,
        allowReviewersToSetCurrentReviewer: false,
        launchNotificationEnabled: false,
        reminderNotificationEnabled: false,
        preferedSalaryDisplay: CompensationReviewSalaryDisplay.ON_TARGET_EARNINGS,
      },
      select: { id: true, companyId: true },
    });

    await ctx.prisma.compensationReviewBudget.create({
      data: {
        campaignId: campaign.id,
        name: ctx.t("services.compensation-review.campaigns.admin.createCampaign.default-budget-name"),
        compensationItems: [
          CompensationReviewCompensationItem.BASE_SALARY,
          CompensationReviewCompensationItem.VARIABLE_PAY,
        ],
        isPromotionBudget: false,
        isAdminBudget: false,
        relativeVariablePayEnabled: false,
      },
    });

    const { campaignCurrency } = await refreshCampaignCurrencies(ctx, { campaign });

    await ctx.prisma.compensationReviewCampaign.update({
      where: { id: campaign.id },
      data: { currencyId: campaignCurrency.id },
    });

    return campaign;
  });

  await sendSynchroniseCompensationReviewCampaignEmployeesJob(ctx, {
    companyId: user.companyId,
    campaignId: campaign.id,
  });

  return campaign;
};

/**
 * Required by Galeries Lafayette and done in the back-end only for now.
 * https://linear.app/figures/issue/FIG-3518/gl-hide-the-frequency-toggle-with-a-setting
 */
const getDisplaySalaryPeriodSelector = (ctx: AppContext) => {
  if (getRequiredUser(ctx).company.name === "Galeries Lafayette") {
    return false;
  }

  return true;
};
