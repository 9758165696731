import { AdditionalFieldNature, Gender, type Prisma, SalaryGridMeasure } from "@prisma/client";
import { type TFunction } from "next-i18next";
import { match, P } from "ts-pattern";
import { chain, compact, isNil, sum } from "~/lib/lodash";
import { assertNotNil } from "~/lib/utils";
import {
  getAllAdditionalFields,
  type GetAllAdditionalFieldsResult,
} from "~/services/additional-field/getAllAdditionalFields";
import { externalEmployeeSelectForDisplay } from "~/services/compensation-review/campaigns/admin/fetchCompensationReviewEmployees";
import { type CompensationReviewContext } from "~/services/compensation-review/compensationReviewContext";
import { prismaCompensationReviewScope } from "~/services/compensation-review/compensationReviewScope";
import { formatExternalEmployeeName } from "~/services/external-employee";
import { formatGender } from "~/services/external-employee/gender";
import { getRelevantPositionings, getSalaryBandPositioningDetails } from "~/services/salaryBandsPositioning";

export const getEmployeesGroupOptions = (t: TFunction, params: { additionalFields: GetAllAdditionalFieldsResult }) => {
  return [
    { id: "job", name: t("pages.compensation-review.employee-groups.job") },
    { id: "level", name: t("pages.compensation-review.employee-groups.level") },
    { id: "location", name: t("pages.compensation-review.employee-groups.location") },
    { id: "gender", name: t("pages.compensation-review.employee-groups.gender") },
    { id: "performance", name: t("pages.compensation-review.employee-groups.performance-rating") },
    { id: "is-promoted", name: t("pages.compensation-review.employee-groups.is-promoted") },
    { id: "business-unit", name: t("pages.compensation-review.employee-groups.business-unit") },
    { id: "reviewer", name: t("pages.compensation-review.employee-groups.reviewer") },
    { id: "range-positioning", name: t("pages.compensation-review.employee-groups.range-positioning") },
    ...params.additionalFields
      .filter((field) => field.nature === AdditionalFieldNature.STRING)
      .map((field) => ({
        id: `additional-field-${field.id}`,
        name: field.name,
      })),
  ];
};

export const generateEmployeesGroups = async (
  ctx: CompensationReviewContext,
  params: {
    budgetId?: number;
    groupBy: string | null;
  }
) => {
  type Group = {
    id: string;
    name: string;
    where: Prisma.CompensationReviewEmployeeWhereInput;
  };

  if (!params.groupBy) {
    return {
      name: ctx.t("pages.compensation-review.employee-groups.no-group"),
      isGlobal: true,
      items: [{ id: "global", name: ctx.t("pages.compensation-review.employee-groups.no-group"), where: {} }],
    };
  }

  const additionalFields = await getAllAdditionalFields(ctx);

  const group = getEmployeesGroupOptions(ctx.t, { additionalFields }).find((option) => option.id === params.groupBy);
  if (!group) {
    return {
      name: ctx.t("pages.compensation-review.employee-groups.group-not-found"),
      isGlobal: true,
      items: [{ id: "global", name: ctx.t("pages.compensation-review.employee-groups.no-group"), where: {} }],
    };
  }

  const baseWhere = {
    eligibilities: { some: {} },
    ...prismaCompensationReviewScope(ctx.scope),
  };

  const items = await match<string, Promise<Group[]>>(params.groupBy)
    .with("job", () => generateJobsGroup(ctx, baseWhere))
    .with("level", () => generateLevelsGroup(ctx, baseWhere))
    .with("location", () => generateLocationsGroup(ctx, baseWhere))
    .with("gender", () => generateGendersGroup(ctx.t_en))
    .with("performance", () => generatePerformancesGroup(ctx, baseWhere))
    .with("business-unit", () => generateBusinessUnitsGroup(ctx, baseWhere))
    .with("is-promoted", () => generatePromotionsGroup(ctx, baseWhere))
    .with("reviewer", () => generateReviewersGroup(ctx, params))
    .with("range-positioning", () => generateRangePositioningsGroup(ctx))
    .with(P.string.regex(/additional-field-(\d)+/), async (column) =>
      generateAdditionalFieldsGroup(ctx, baseWhere, { fieldId: column })
    )
    .run();

  return {
    name: group.name,
    isGlobal: false,
    items,
  };
};

const generateReviewersGroup = async (ctx: CompensationReviewContext, params: { budgetId?: number }) => {
  const firstReviewersWithCounts = await ctx.prisma.compensationReviewEmployee.groupBy({
    by: ["reviewer1Id"],
    where: {
      ...prismaCompensationReviewScope(ctx.scope),
      ...(params.budgetId && {
        eligibilities: {
          some: {
            budgetId: params.budgetId,
          },
        },
      }),
    },
    _count: true,
  });

  const reviewers = await ctx.prisma.compensationReviewReviewer.findMany({
    where: {
      id: { in: compact(firstReviewersWithCounts.map((reviewer) => reviewer.reviewer1Id)) },
    },
    select: {
      id: true,
      externalEmployee: {
        select: externalEmployeeSelectForDisplay,
      },
    },
  });

  const reviewersWithCount = reviewers.map((reviewer) => ({
    ...reviewer,
    _count: firstReviewersWithCounts.find((firstReviewer) => firstReviewer.reviewer1Id === reviewer.id)?._count ?? 0,
  }));

  return chain(reviewersWithCount)
    .orderBy((reviewer) => sum(Object.values(reviewer._count)), "desc")
    .map((reviewer) => ({
      id: `${reviewer.id}`,
      name: formatExternalEmployeeName(reviewer.externalEmployee),
      where: { reviewer1Id: reviewer.id } as Prisma.CompensationReviewEmployeeWhereInput,
    }))
    .thru((items) => {
      const noReviewersRow = firstReviewersWithCounts.find((reviewer) => reviewer.reviewer1Id === null);

      if (noReviewersRow) {
        items.push({
          id: "none",
          name: ctx.t("pages.compensation-review.employee-groups.no-reviewer"),
          where: { reviewer1Id: null },
        });
      }

      return items;
    })
    .value();
};

const generateJobsGroup = async (
  ctx: CompensationReviewContext,
  baseWhere: Prisma.CompensationReviewEmployeeWhereInput
) => {
  const jobs = await ctx.prisma.externalJob.groupBy({
    by: ["id", "name"],
    where: {
      employees: {
        some: {
          compensationReviewEmployees: {
            some: baseWhere,
          },
        },
      },
    },
    _count: true,
  });

  return chain(jobs)
    .orderBy((job) => job._count, "desc")
    .map((job) => ({
      id: job.id ? `${job.id}` : "none",
      name: job.name ?? ctx.t("pages.compensation-review.employee-groups.no-job"),
      where: { externalEmployee: { jobId: job.id } },
    }))
    .value();
};

const generateRangePositioningsGroup = async (ctx: CompensationReviewContext) => {
  if (isNil(ctx.salaryGrid)) {
    return [];
  }

  const rangePositionings = [...getRelevantPositionings(ctx.salaryGrid.tiersMode)].reverse();

  return chain(rangePositionings)
    .map((positioning) => ({
      id: positioning,
      name: getSalaryBandPositioningDetails(ctx.t, {
        tiersMode: assertNotNil(ctx.salaryGrid).tiersMode,
        tiersNames: assertNotNil(ctx.salaryGrid).tiersNames,
        positioning,
      }).title,
      where: {
        externalEmployee: {
          liveSalaryRangeEmployee: {
            OR: [
              {
                range: {
                  band: { measure: SalaryGridMeasure.BASE_SALARY },
                },
                baseSalaryRangePositioning: positioning,
              },
              {
                range: {
                  band: { measure: SalaryGridMeasure.ON_TARGET_EARNINGS },
                },
                onTargetEarningsRangePositioning: positioning,
              },
            ],
          },
        },
      },
    }))
    .value();
};

const generateLevelsGroup = async (
  ctx: CompensationReviewContext,
  baseWhere: Prisma.CompensationReviewEmployeeWhereInput
) => {
  const levels = await ctx.prisma.externalLevel.groupBy({
    by: ["id", "name"],
    where: {
      employees: {
        some: {
          compensationReviewEmployees: {
            some: baseWhere,
          },
        },
      },
    },
    _count: true,
  });

  return chain(levels)
    .orderBy((level) => level._count, "desc")
    .map((level) => ({
      id: level.id ? `${level.id}` : "none",
      name: level.name ?? ctx.t("pages.compensation-review.employee-groups.no-level"),
      where: { externalEmployee: { levelId: level.id } },
    }))
    .value();
};

const generateLocationsGroup = async (
  ctx: CompensationReviewContext,
  baseWhere: Prisma.CompensationReviewEmployeeWhereInput
) => {
  const locations = await ctx.prisma.externalLocation.groupBy({
    by: ["id", "name"],
    where: {
      employees: {
        some: {
          compensationReviewEmployees: {
            some: baseWhere,
          },
        },
      },
    },
    _count: true,
  });

  return chain(locations)
    .orderBy((location) => location._count, "desc")
    .map((location) => ({
      id: location.id ? `${location.id}` : "none",
      name: location.name ?? ctx.t("pages.compensation-review.employee-groups.no-location"),
      where: { externalEmployee: { locationId: location.id } },
    }))
    .value();
};

const generateGendersGroup = async (t: TFunction) => {
  return Object.values(Gender).map((gender) => ({
    id: gender,
    name: formatGender(t, gender),
    where: { externalEmployee: { gender } },
  }));
};

const generatePerformancesGroup = async (
  ctx: CompensationReviewContext,
  baseWhere: Prisma.CompensationReviewEmployeeWhereInput
) => {
  const performanceRatings = await ctx.prisma.performanceReviewRating.groupBy({
    by: ["id", "name", "position"],
    where: {
      compensationReviewEmployees: {
        some: baseWhere,
      },
    },
    _count: true,
  });

  return chain(performanceRatings)
    .orderBy((performanceRating) => performanceRating.position, "asc")
    .map((performanceRating) => ({
      id: performanceRating.id ? `${performanceRating.id}` : "none",
      name: performanceRating.name ?? ctx.t("pages.compensation-review.employee-groups.no-performance-rating"),
      where: { performanceRatingId: performanceRating.id } as Prisma.CompensationReviewEmployeeWhereInput,
    }))
    .concat([
      {
        id: "none",
        name: ctx.t("pages.compensation-review.employee-groups.no-performance-rating"),
        where: { performanceRatingId: null },
      },
    ])
    .value();
};

const generateBusinessUnitsGroup = async (
  ctx: CompensationReviewContext,
  baseWhere: Prisma.CompensationReviewEmployeeWhereInput
) => {
  const businessUnits = await ctx.prisma.externalEmployee.groupBy({
    by: ["businessUnit"],
    where: {
      compensationReviewEmployees: {
        some: baseWhere,
      },
    },
    _count: true,
  });

  return chain(businessUnits)
    .orderBy((businessUnit) => businessUnit._count, "desc")
    .map((businessUnit) => ({
      id: businessUnit.businessUnit ?? "none",
      name: businessUnit.businessUnit ?? ctx.t("pages.compensation-review.employee-groups.no-business-unit"),
      where: { externalEmployee: { businessUnit: businessUnit.businessUnit } },
    }))
    .value();
};

const generatePromotionsGroup = async (
  ctx: CompensationReviewContext,
  baseWhere: Prisma.CompensationReviewEmployeeWhereInput
) => {
  return Object.values([true, false]).map((isPromoted) => ({
    id: `${isPromoted}`,
    name: isPromoted ? ctx.t("common.yes") : ctx.t("common.no"),
    where: { ...baseWhere, isPromoted },
  }));
};

const generateAdditionalFieldsGroup = async (
  ctx: CompensationReviewContext,
  baseWhere: Prisma.CompensationReviewEmployeeWhereInput,
  params: { fieldId: string }
) => {
  const additionalFieldId = parseInt((params.fieldId.split("-") as [string, string, string])[2]);

  const additionalFieldValues = await ctx.prisma.additionalFieldValue.groupBy({
    by: ["stringValue"],
    where: {
      additionalFieldId,
      externalEmployee: {
        compensationReviewEmployees: {
          some: baseWhere,
        },
      },
    },
  });

  return additionalFieldValues
    .map((additionalFieldValue) => ({
      id: additionalFieldValue.stringValue ?? "none",
      name: additionalFieldValue.stringValue ?? ctx.t("pages.compensation-review.employee-groups.no-value"),
      where: {
        externalEmployee: {
          additionalFieldValues: {
            some: { additionalFieldId: additionalFieldId, stringValue: additionalFieldValue.stringValue },
          },
        },
      } as Prisma.CompensationReviewEmployeeWhereInput,
    }))
    .concat([
      {
        id: "none",
        name: ctx.t("pages.compensation-review.employee-groups.no-value"),
        where: {
          externalEmployee: { additionalFieldValues: { none: { additionalFieldId: additionalFieldId } } },
        },
      },
    ]);
};
