import {
  EmployeeStatus,
  ExternalRemunerationType,
  Gender,
  type EmployeeLevel,
  type Prisma,
  type EmployeeLevel as PrismaEmployeeLevel,
} from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { chain, compact, isNil, sumBy } from "~/lib/lodash";
import { roundTo } from "~/lib/math";
import { arrayHasValues } from "~/lib/utils";
import { whereCompanyIsPartOfDataset } from "~/services/company";
import { generateEmployeeBucketHash } from "~/services/company-dashboard/generateEmployeeBucketHash";
import { type ComputeEmployeeCompensationInput } from "~/services/employee";
import { getAllowedLevels } from "~/services/employee/employeeLevel";
import { getAllowedCountryIds } from "~/services/employee/employeeLocation";
import { getHeadcountRange } from "~/services/headcount";
import { getAllowedJobFamilyIds } from "~/services/job";
import { type GetMarketDataStatsEmployeesOptions } from "~/services/market-data/getMarketDataStats";

export const getMarketDataEmployees = async (ctx: AppContext, options: GetMarketDataStatsEmployeesOptions = {}) => {
  const allowedEmployees = await fetchMarketDataEmployees(ctx, options);

  if (options.useTestData) {
    return allowedEmployees.map(anonymiseMarketDataEmployee);
  }

  return allowedEmployees;
};

const marketDataEmployeeSelect = {
  id: true,
  companyId: true,
  locationId: true,
  currencyId: true,
  surveyId: true,
  jobId: true,
  gender: true,
  firstName: true,
  lastName: true,
  employeeNumber: true,
  company: { select: { tags: { select: { id: true } } } },
  level: true,
  benchmarkLevel: {
    select: {
      id: true,
      translations: true,
      min: true,
      max: true,
    },
  },
  baseSalary: true,
  fixedBonus: true,
  onTargetBonus: true,
  currency: { select: { euroExchangeRate: true, code: true, decimals: true } },
  survey: {
    select: {
      totalFunding: true,
      growthRate: true,
      lastFundingRound: true,
    },
  },
  job: {
    select: { name: true, familyId: true },
  },
  location: { select: { name: true, country: { select: { name: true, alpha2: true } } } },
} satisfies Prisma.EmployeeSelect;

export const externalEmployeeRemunerationItemSelect = {
  remunerationItems: {
    select: {
      amount: true,
      nature: {
        select: {
          mappedType: true,
          mappedSubType: true,
        },
      },
    },
  },
} as const;

type BaseMarketDataEmployee = Prisma.EmployeeGetPayload<{
  select: typeof marketDataEmployeeSelect;
}>;

type MarketDataEmployeeWithExternal = Prisma.EmployeeGetPayload<{
  select: typeof marketDataEmployeeSelect & {
    externalEmployee: {
      select: typeof externalEmployeeRemunerationItemSelect;
    };
  };
}>;

export type MarketDataEmployee = BaseMarketDataEmployee | MarketDataEmployeeWithExternal;

const buildMarketDataEmployeesSelect = (ctx: AppContext) => {
  return {
    ...marketDataEmployeeSelect,
    ...(ctx.featureFlags.CAN_ACCESS_DETAILED_MEASURES && {
      externalEmployee: { select: externalEmployeeRemunerationItemSelect },
    }),
  };
};

const fetchMarketDataEmployees = async (ctx: AppContext, options: GetMarketDataStatsEmployeesOptions) => {
  return ctx.prisma.employee.findMany({
    where: buildMarketDataStatsEmployeesWhere(ctx, options),
    select: buildMarketDataEmployeesSelect(ctx),
  });
};

export const getRemunerationItemsByNature = (employee: MarketDataEmployee): ComputeEmployeeCompensationInput => {
  if ("externalEmployee" in employee && employee.externalEmployee) {
    const groupedItems = chain(employee.externalEmployee.remunerationItems)
      .groupBy((item) => item.nature.mappedType)
      .mapValues((items) => sumBy(items, (item) => item.amount || 0))
      .value();

    return {
      baseSalary: employee.baseSalary,
      fixedBonus: employee.fixedBonus,
      onTargetBonus: employee.onTargetBonus,
      currency: employee.currency,
      profitSharing: groupedItems[ExternalRemunerationType.PROFIT_SHARING] || null,
      equity: groupedItems[ExternalRemunerationType.EQUITY] || null,
      paidBonus: groupedItems[ExternalRemunerationType.PAID_BONUS] || null,
    };
  }

  return {
    baseSalary: employee.baseSalary,
    fixedBonus: employee.fixedBonus,
    onTargetBonus: employee.onTargetBonus,
    currency: employee.currency,
    profitSharing: null,
    equity: null,
    paidBonus: null,
  };
};

const RefBaseSalaries: { [key in EmployeeLevel]: number } = {
  BEGINNER: 35_000,
  JUNIOR: 40_000,
  INTERMEDIATE: 45_000,
  SENIOR: 50_000,
  STAFF: 65_000,
  PRINCIPAL: 75_000,
  TEAM_LEAD: 60_000,
  MANAGER: 80_000,
  HEAD_OF: 100_000,
  DIRECTOR: 120_000,
  VP: 130_000,
  C_LEVEL: 140_000,
};

const getRandomNumberInRange = (params: { min: number; max: number }) => {
  const min = Math.ceil(params.min);
  const max = Math.floor(params.max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const anonymiseMarketDataEmployee = (employee: MarketDataEmployee): MarketDataEmployee => {
  // Base salaries are based on level and added a +/- 20% random variation
  const refBaseSalary = RefBaseSalaries[employee.level];
  const variation = getRandomNumberInRange({ min: -20, max: 20 }) / 100;
  const baseSalary = Math.round(refBaseSalary * (1 + variation) * 100);

  // 25% of employees have fixed bonuses between 100€ & 10000€
  const hasFixedBonus = getRandomNumberInRange({ min: 0, max: 100 }) <= 25;
  const fixedBonus = hasFixedBonus ? roundTo(getRandomNumberInRange({ min: 100, max: 10000 }), 100) * 100 : null;

  // 100% of sales have variable bonuses of 50% of their base salary with 80% realistic payout rate
  // 50% of marketing have 50% variable bonuses of 50% of their base salary with 80% realistic payout rate
  // Other job families don't have variable bonuses
  const hasVariableBonus = getRandomNumberInRange({ min: 0, max: 100 });
  const onTargetBonus = hasVariableBonus ? baseSalary * 0.5 : null;

  // 60% of employees are male
  const gender = getRandomNumberInRange({ min: 0, max: 100 }) <= 60 ? Gender.MALE : Gender.FEMALE;

  return {
    ...employee,
    baseSalary,
    onTargetBonus,
    fixedBonus,

    firstName: "Example",
    lastName: "Employee",
    employeeNumber: "#0001",

    gender,
  };
};

const buildMarketDataStatsEmployeesWhere = (ctx: AppContext, options: GetMarketDataStatsEmployeesOptions) => {
  const allowedJobFamilyIds = getAllowedJobFamilyIds(ctx.user);
  const allowedCountryIds = getAllowedCountryIds(ctx.user);
  const allowedLevels = getAllowedLevels(ctx.user, options.mergeAdvancedLevels).filter(
    (level) => level !== "MA0X"
  ) as PrismaEmployeeLevel[];
  const companySize = ctx.featureFlags.CAN_ACCESS_FIGURES_AI_V2
    ? getHeadcountRange(options.headcount)
    : options.companySize;

  const canAccessBenchmarkLevels = ctx.featureFlags.CAN_ACCESS_LEVEL_FRAMEWORKS || options.useBenchmarkLevelsInShare;

  return {
    company: whereCompanyIsPartOfDataset(ctx.user),
    status: EmployeeStatus.LIVE,
    employeeDataValidationFlags: { none: { isLive: true } },

    AND: compact([
      !isNil(options.externalEmployeeIds) && { externalEmployee: { id: { in: options.externalEmployeeIds } } },
      arrayHasValues(options.companyIds) && { companyId: { in: options.companyIds } },
      options.omitCompanyId && { companyId: { not: options.omitCompanyId } },
      options.fundingRounds && { survey: { lastFundingRound: { in: options.fundingRounds } } },
      companySize && companySize.min !== null && { survey: { employeesCount: { gte: companySize.min } } },
      companySize && companySize.max !== null && { survey: { employeesCount: { lte: companySize.max } } },
      options.growthRates && {
        OR: options.growthRates.map((growthRate) => ({
          AND: compact([
            growthRate.min !== null && { survey: { growthRate: { gte: growthRate.min } } },
            growthRate.max !== null && { survey: { growthRate: { lte: growthRate.max } } },
          ]),
        })),
      },
      options.labels && { company: { tags: { some: { id: { in: options.labels.tagIds } } } } },
      options.industries && { company: { tags: { some: { id: { in: options.industries.tagIds } } } } },
      !ctx.featureFlags.CAN_ACCESS_LEVEL_FRAMEWORKS &&
        arrayHasValues(options.levels) && { level: { in: options.levels } },
      !ctx.featureFlags.CAN_ACCESS_LEVEL_FRAMEWORKS &&
        arrayHasValues(allowedLevels) && { level: { in: allowedLevels } },
      arrayHasValues(options.jobIds) && { job: { id: { in: options.jobIds } } },
      arrayHasValues(allowedJobFamilyIds) && { job: { familyId: { in: allowedJobFamilyIds } } },
      arrayHasValues(options.countryIds) && { location: { countryId: { in: options.countryIds } } },
      arrayHasValues(options.locationIds) && { location: { id: { in: options.locationIds } } },
      arrayHasValues(allowedCountryIds) && { location: { countryId: { in: allowedCountryIds } } },
      arrayHasValues(options.buckets) && { bucketHash: { in: options.buckets.map(generateEmployeeBucketHash) } },
      canAccessBenchmarkLevels &&
        options.benchmarkLevelsRange &&
        options.benchmarkLevelsRange?.max > 0 && {
          OR: [
            {
              benchmarkLevel: {
                min: { lte: options.benchmarkLevelsRange.max },
                max: { gte: options.benchmarkLevelsRange.min },
              },
            },
            {
              benchmarkLevel: {
                min: { gte: options.benchmarkLevelsRange.min },
                max: { lte: options.benchmarkLevelsRange.max },
              },
            },
          ],
        },
    ]),
  } satisfies Prisma.EmployeeWhereInput;
};
