import { Stack, Typography } from "@mui/material";
import { type Currency } from "@prisma/client";
import { TitledTooltip } from "~/components/ui/core/TitledTooltip";
import { useMoney } from "~/hooks/useMoney";
import { useI18n } from "~/lib/i18n/useI18n";
import { formatPercent } from "~/lib/math";

type Props = {
  fteDivider: number | null;
  value: number;
  currency: Pick<Currency, "code" | "decimals">;
  children: JSX.Element;
};

export const FteTooltip: React.FC<Props> = ({ fteDivider, value, currency, children }) => {
  const money = useMoney(currency);
  const { t } = useI18n();

  if (fteDivider === null || fteDivider === 1) {
    return children;
  }

  return (
    <TitledTooltip
      className="w-64"
      heading={t("pages.compensation-review.recommendation-form.fte-tooltip.title")}
      text={
        <Stack alignItems="center">
          <Stack direction="row" className="w-full justify-between">
            <Typography variant="caption">
              {t("pages.compensation-review.recommendation-form.fte-tooltip.actual-amount")}
            </Typography>
            <Typography variant="caption">{money.format(value, { roundTo: 1 })}</Typography>
          </Stack>
          <Stack direction="row" className="w-full justify-between">
            <Typography variant="caption">
              {t("pages.compensation-review.recommendation-form.fte-tooltip.full-time-equivalent")}
            </Typography>
            <Typography variant="caption">{money.format(value / fteDivider, { roundTo: 1 })}</Typography>
          </Stack>
          <Stack direction="row" className="w-full justify-between">
            <Typography variant="caption">
              {t("pages.compensation-review.recommendation-form.fte-tooltip.working-time")}
            </Typography>
            <Typography variant="caption">{formatPercent(fteDivider)}</Typography>
          </Stack>
        </Stack>
      }
      placement="top"
    >
      {children}
    </TitledTooltip>
  );
};
