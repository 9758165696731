import { compact } from "~/lib/lodash";

export const makeSingletonKey = (params: Record<string, string | number>, options: { unique: boolean }) => {
  // This guarantees we'll always get the same order of keys
  const valuesSortedByKeys = Object.entries(params)
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
    .map(([, value]) => value);

  // If you update this piece, make sure to check that the frontend can
  // actually still fetch the job queue status through useJobQueueStatus
  return compact([...valuesSortedByKeys, options?.unique && Date.now()]).join("-");
};
