import { Alert, Stack, Typography } from "@mui/material";
import { QueueJobStatus } from "@prisma/client";
import { useQueryClient } from "@tanstack/react-query";
import React, { useContext, useEffect, useState } from "react";
import { match } from "ts-pattern";
import { Button } from "~/components/ui/core/Button";
import { NextLinkComposed } from "~/components/ui/core/Link";
import { ProgressBar } from "~/components/ui/core/ProgressBar";
import { SnackBar } from "~/components/ui/core/SnackBar";
import { useJobQueue } from "~/hooks/useJobQueue";
import { useSession } from "~/hooks/useSession";
import { useI18n } from "~/lib/i18n/useI18n";
import { makeSingletonKey } from "~/lib/makeSingletonKey";
import { type JobOutput } from "~/lib/queue/fetchJobQueueJobStatus";
import { QueueJobName } from "~/lib/queue/queueJobName";
import { GET_ALL_ADDITIONAL_FIELD_QUERY_KEY } from "~/pages/api/additional-field/get-all-additional-field";
import { FETCH_COMPANY_EMPLOYEES_QUERY_KEY } from "~/pages/api/employee/fetch-company-employees";
import { COUNT_EXTERNAL_EMPLOYEES_BY_STATUS_QUERY_KEY } from "~/pages/api/external-employee/count-external-employees-by-status";
import { FETCH_ALL_IMPORTED_EMPLOYEES_QUERY_KEY } from "~/pages/api/imported-employees/fetch-all-imported-employees";
import { FETCH_PERFORMANCE_REVIEW_RATINGS_QUERY_KEY } from "~/pages/api/performance-review/fetch-performance-review-ratings";
import { getSpreadsheetFlowRoute } from "~/services/integrations/utils";

type ImportSpreadsheetProgressProps = {
  message: string;
  inProgress: boolean;
  progress: number | undefined;
  jobStatus: QueueJobStatus | null;
  triggerSyncPolling: () => void;
  isSnackBarOpen: boolean;
  setIsSnackBarOpen: (isSnackBarOpen: boolean) => void;
  jobOutput: JobOutput | null;
  setImportId: (importId: number) => void;
};

const ImportSpreadsheetProgressContext = React.createContext<ImportSpreadsheetProgressProps | null>(null);

export const ImportSpreadsheetSnackbar: React.FC = () => {
  const { t } = useI18n();
  const { message, jobStatus, isSnackBarOpen, setIsSnackBarOpen, jobOutput, progress } = useImportSpreadsheetProgress();

  return (
    <SnackBar open={isSnackBarOpen} autoHideDuration={null} message={message} onClose={() => setIsSnackBarOpen(false)}>
      <Stack className="mt-2 w-full" spacing={2}>
        <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
          {(jobStatus === QueueJobStatus.IN_PROGRESS || jobStatus === QueueJobStatus.SCHEDULED) && (
            <ProgressBar className="w-full" BarProps={progress ? { value: progress, variant: "determinate" } : {}} />
          )}
          {jobStatus === QueueJobStatus.COMPLETED && (
            <ProgressBar className="w-full" BarProps={{ value: 100, variant: "determinate" }} />
          )}
        </Stack>
        {jobStatus === QueueJobStatus.FAILED && (
          <Alert severity="error" color="secondary">
            <Stack spacing={2}>
              {jobOutput ? (
                <Typography variant="caption">{jobOutput.message}</Typography>
              ) : (
                <Typography variant="caption">{t("components.ui.spreadsheet-import.unknown-error")}</Typography>
              )}
              <Button
                size="small"
                className="!ml-auto mt-1"
                variant="outlined"
                color="secondary"
                component={NextLinkComposed}
                to={getSpreadsheetFlowRoute()}
              >
                {t("components.ui.spreadsheet-import.try-again")}
              </Button>
            </Stack>
          </Alert>
        )}
      </Stack>
    </SnackBar>
  );
};

export const useImportSpreadsheetProgress = (): ImportSpreadsheetProgressProps => {
  const context = useContext(ImportSpreadsheetProgressContext);

  if (!context) {
    throw new Error("useImportSpreadsheetProgress must be used within a ImportSpreadsheetProgressProvider");
  }

  return context;
};

export const ImportSpreadsheetProgressProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useI18n();
  const { user } = useSession();
  const queryClient = useQueryClient();
  const [isPolling, setIsPolling] = useState(false);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [importId, setImportId] = useState<number | null>(null);

  const syncJob = useJobQueue({
    jobName: QueueJobName.IMPORT_SPREADSHEET,
    multipartJob: true,
    singletonKey: makeSingletonKey({ companyId: user?.company.id ?? "", importId: importId ?? "" }, { unique: false }),
    enabled: !!user && !!importId,
    enablePolling: isPolling,
    onComplete: async () => {
      await queryClient.invalidateQueries([FETCH_ALL_IMPORTED_EMPLOYEES_QUERY_KEY]);
      await queryClient.invalidateQueries([COUNT_EXTERNAL_EMPLOYEES_BY_STATUS_QUERY_KEY]);
      await queryClient.invalidateQueries([GET_ALL_ADDITIONAL_FIELD_QUERY_KEY]);
      await queryClient.invalidateQueries([FETCH_PERFORMANCE_REVIEW_RATINGS_QUERY_KEY]);
      await queryClient.invalidateQueries([FETCH_COMPANY_EMPLOYEES_QUERY_KEY]);
    },
  });

  const inProgress = syncJob.jobStatus === QueueJobStatus.IN_PROGRESS || syncJob.jobStatus === QueueJobStatus.SCHEDULED;

  useEffect(() => {
    if (inProgress) {
      setIsSnackBarOpen(true);
    }
  }, [inProgress, syncJob.jobStatus]);

  const triggerSyncPolling = async () => {
    syncJob.setJobStatus(QueueJobStatus.SCHEDULED);
    syncJob.setJobProgress(0);

    setIsPolling(true);
  };

  const displayedMessage = match(syncJob.jobStatus)
    .with(QueueJobStatus.FAILED, () => t("components.ui.spreadsheet-import.error-message"))
    .with(QueueJobStatus.COMPLETED, () => t("components.ui.spreadsheet-import.success-message"))
    .otherwise(() => t("components.ui.spreadsheet-import.sync-message"));

  return (
    <ImportSpreadsheetProgressContext.Provider
      value={{
        inProgress,
        progress: syncJob.progress,
        jobStatus: syncJob.jobStatus,
        message: displayedMessage,
        triggerSyncPolling,
        isSnackBarOpen,
        setIsSnackBarOpen,
        jobOutput: syncJob.jobOutput,
        setImportId,
      }}
    >
      {children}
    </ImportSpreadsheetProgressContext.Provider>
  );
};
