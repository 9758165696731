import { type Prisma } from "@prisma/client";

export const selectCompensationReviewCurrency = {
  id: true,
  euroExchangeRate: true,
  currency: {
    select: {
      code: true,
      name: true,
      symbol: true,
      decimals: true,
    },
  },
} satisfies Prisma.CompensationReviewCurrencySelect;

type CompensationReviewCurrency = Prisma.CompensationReviewCurrencyGetPayload<{
  select: typeof selectCompensationReviewCurrency;
}>;

export const transformCompensationReviewCurrency = (currency: CompensationReviewCurrency) => {
  return {
    id: currency.id,
    euroExchangeRate: currency.euroExchangeRate,
    ...currency.currency,
  };
};

export type TransformCompensationReviewCurrencyResult = ReturnType<typeof transformCompensationReviewCurrency>;
