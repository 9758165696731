import { useEffect } from "react";

import { useState } from "react";

export const useStateWithSync = <T>(initialValue: T) => {
  const [value, setValue] = useState<T>(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return [value, setValue] as const;
};
