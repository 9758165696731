import { CompensationReviewSalaryPeriod, type PerformanceReviewRating, type Prisma } from "@prisma/client";
import { compensationReviewEmployeeSelectForReviewersChain } from "~/services/compensation-review/campaigns/admin/compensationReviewEmployeeSelectForReviewers";
import { computeCompensationReviewEmployeeSalaryPayload } from "~/services/compensation-review/campaigns/admin/computeCompensationReviewEmployeePayload";
import { buildReviewersUpdatePayload } from "~/services/compensation-review/campaigns/admin/employeeReviewers";
import { type CompensationReviewContext } from "~/services/compensation-review/compensationReviewContext";
import { prismaCompensationReviewScope } from "~/services/compensation-review/compensationReviewScope";
import { findOrCreateCompensationReviewReviewers } from "~/services/compensation-review/shared/findOrCreateCompensationReviewReviewer";

export const externalEmployeeSelectForCompensationReviewEmployeeCreation = {
  id: true,
  currency: true,
  fteDivider: true,
  performanceReviewRating: {
    select: {
      name: true,
      position: true,
    },
  },
  remunerationItems: {
    select: {
      amount: true,
      nature: {
        select: { mappedType: true },
      },
    },
  },
  mappedEmployee: {
    select: {
      baseSalary: true,
      fixedBonus: true,
      fixedBonusPercentage: true,
      onTargetBonus: true,
      onTargetBonusPercentage: true,
      currency: {
        select: {
          code: true,
          euroExchangeRate: true,
          decimals: true,
        },
      },
    },
  },
  location: {
    select: {
      externalId: true,
    },
  },
  liveSalaryRangeEmployee: {
    select: {
      id: true,
      orderingCompaRatio: true,
      orderingRangePenetration: true,
      range: {
        select: {
          midpoint: true,
          min: true,
          max: true,
          band: { select: { measure: true, isDraft: true } },
        },
      },
    },
  },
} satisfies Prisma.ExternalEmployeeSelect;

export type ExternalEmployeeForCompensationReviewEmployeeCreation = Prisma.ExternalEmployeeGetPayload<{
  select: typeof externalEmployeeSelectForCompensationReviewEmployeeCreation;
}>;

export const getPublishedSalaryRangeEmployee = (
  externalEmployee: ExternalEmployeeForCompensationReviewEmployeeCreation
) =>
  externalEmployee.liveSalaryRangeEmployee && !externalEmployee.liveSalaryRangeEmployee.range.band.isDraft
    ? externalEmployee.liveSalaryRangeEmployee
    : null;

export const createCompensationReviewEmployee = async (
  ctx: CompensationReviewContext,
  params: {
    externalEmployee: ExternalEmployeeForCompensationReviewEmployeeCreation;
    compensationReviewCurrencyId: number;
    performanceRatings: PerformanceReviewRating[];
    promotion?: {
      targetRangeId: number | null;
    };
    numberOfMonths: number;
  }
) => {
  const performanceRating = params.performanceRatings.find(
    (rating) => rating?.name === params.externalEmployee.performanceReviewRating?.name
  );

  const salaryRangeEmployee = getPublishedSalaryRangeEmployee(params.externalEmployee);

  const reviewers = await findOrCreateCompensationReviewReviewers(ctx, {
    externalEmployeeId: params.externalEmployee.id,
  });

  const salaryPayload = computeCompensationReviewEmployeeSalaryPayload(ctx, params.externalEmployee);

  return ctx.prisma.compensationReviewEmployee.create({
    data: {
      ...prismaCompensationReviewScope(ctx.scope),
      ...buildReviewersUpdatePayload(reviewers),
      companyId: ctx.companyId,
      currencyId: params.compensationReviewCurrencyId,
      externalEmployeeId: params.externalEmployee.id,
      isPromoted: !!params.promotion,
      hasCustomReviewChain: false,
      numberOfMonths: params.numberOfMonths,
      defaultSalaryPeriod: CompensationReviewSalaryPeriod.YEARLY,
      ...salaryPayload,
      isIncreaseDifferentFromRecommendation: false,
      ...(performanceRating && { performanceRatingId: performanceRating.id }),
      ...(salaryRangeEmployee && {
        compaRatio: salaryRangeEmployee.orderingCompaRatio,
        rangePenetration: salaryRangeEmployee.orderingRangePenetration,
        salaryBandMeasure: salaryRangeEmployee.range.band.measure,
      }),
      ...(params.promotion?.targetRangeId && {
        targetRangeAfterPromotionId: params.promotion.targetRangeId,
      }),
    },
    select: compensationReviewEmployeeSelectForReviewersChain,
  });
};
