import { getCookieConfig } from "~/config";
import { type MiddlewareContext, next } from "~/lib/middleware/middleware";
import { type NullableAuthenticatedUser, type RequiredAuthenticatedUser } from "~/services/auth/fetchAuthenticatedUser";

export const updateUserSessionDuration = async (
  ctx: MiddlewareContext<{ user: RequiredAuthenticatedUser; impersonatingUser: NullableAuthenticatedUser }>
) => {
  const company = await ctx.req.prisma.company.findUniqueOrThrow({
    where: { id: ctx.user.companyId },
    select: { userSessionDurationInSeconds: true },
  });

  ctx.req.session.updateConfig(getCookieConfig({ sessionDurationInSeconds: company.userSessionDurationInSeconds }));
  await ctx.req.session.save();

  return next(ctx);
};
