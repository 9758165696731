import { QueueJobStatus } from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { omit } from "~/lib/lodash";
import { CORRELATION_ID_KEY, logError, logInfo } from "~/lib/logger";
import { type BaseJobData } from "~/lib/queue/baseJobDataSchema";
import { JobPriority, type SendOptions } from "~/lib/queue/drivers/queueDriver";
import { makeQueueDriver } from "~/lib/queue/makeQueueDriver";
import { type QueueJobName } from "~/lib/queue/queueJobName";
import { updateQueueJobStatus } from "~/lib/queue/updateQueueJobStatus";

const DEFAULT_JOB_OPTIONS: SendOptions = {
  retryLimit: 0,
  retryDelay: 0,
  retryBackoff: true,
  priority: JobPriority.MEDIUM,
} as const;

type JobParams<T extends BaseJobData> = {
  data: T;
  jobName: QueueJobName;
  options: SendOptions & { singletonKey: string };
};

export const sendJob = async <T extends BaseJobData>(ctx: AppContext, params: JobParams<T>) => {
  const { data, jobName, options } = params;

  const defaultedOptions = { ...DEFAULT_JOB_OPTIONS, ...options };

  const dataToSend = {
    ...data,
    ...(ctx.metadata.has(CORRELATION_ID_KEY) && { correlationId: ctx.metadata.get(CORRELATION_ID_KEY) }),
  };

  try {
    const jobId = await makeQueueDriver().sendJob(ctx, jobName, dataToSend, defaultedOptions);

    if (jobId) {
      logInfo(ctx, "[worker] Job created", { jobId, jobName, data: omit(data, "rows") });

      return updateQueueJobStatus(ctx, {
        name: jobName,
        singletonKey: options.singletonKey,
        status: QueueJobStatus.SCHEDULED,
        externalJobId: jobId,
        companyId: data.companyId,
      });
    }

    if (!options.singletonKey) {
      throw new Error(`Could not create job "${jobName}"`);
    }
  } catch (error) {
    logError(ctx, "[worker] Unexpected error", { error, data: omit(data, "rows") });

    throw error;
  }
};
