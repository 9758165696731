import { type CompensationReviewRecommendation, CompensationReviewRecommendationStatus } from "@prisma/client";
import { isIn } from "~/lib/utils";

export const canUpdateRecommendation = (recommendation: Pick<CompensationReviewRecommendation, "status">) => {
  return isIn(recommendation.status, [
    CompensationReviewRecommendationStatus.PENDING_FOR_REVIEW,
    CompensationReviewRecommendationStatus.PENDING_FOR_SUBMISSION,
    CompensationReviewRecommendationStatus.SUBMITTED,
  ]);
};

export const isPendingRecommendation = (recommendation: Pick<CompensationReviewRecommendation, "status">) => {
  return isIn(recommendation.status, [
    CompensationReviewRecommendationStatus.PENDING_FOR_REVIEW,
    CompensationReviewRecommendationStatus.PENDING_FOR_SUBMISSION,
  ]);
};

export const isDraftRecommendation = (recommendation: Pick<CompensationReviewRecommendation, "status">) => {
  return recommendation.status === CompensationReviewRecommendationStatus.PENDING_FOR_SUBMISSION;
};

export const isCompletedRecommendation = (recommendation: Pick<CompensationReviewRecommendation, "status">) => {
  return isIn(recommendation.status, [
    CompensationReviewRecommendationStatus.SUBMITTED,
    CompensationReviewRecommendationStatus.LOCKED,
  ]);
};
