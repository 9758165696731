import { type Company, type Country, type Currency } from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { type StaticModels } from "~/lib/integration";
import { logWarn } from "~/lib/logger";

type EmployeeCurrencyParams = {
  staticModels: StaticModels;
  company: Pick<Company, "defaultCountryId">;
  currencyCode?: string | null;
  employeeCountry?: Pick<Country, "defaultCurrencyId"> | null;
};

export const getEmployeeCurrency = (ctx: AppContext, params: EmployeeCurrencyParams) => {
  const { staticModels, currencyCode, company, employeeCountry } = params;

  if (!currencyCode && !employeeCountry) {
    return staticModels.currencies.find((currency) => currency.code === "EUR") as Currency;
  }

  const currencyByCode = staticModels.currencies.find((currency) => currency.code === currencyCode);
  if (currencyByCode) {
    return currencyByCode;
  }

  const countryCurrency = staticModels.currencies.find(
    (currency) => currency.id === employeeCountry?.defaultCurrencyId
  );

  if (countryCurrency) {
    return countryCurrency;
  }

  const companyDefaultCountry = staticModels.countries.find((country) => country.id === company.defaultCountryId);
  const companyDefaultCountryCurrency = staticModels.currencies.find(
    (currency) => currency.id === companyDefaultCountry?.defaultCurrencyId
  );
  if (companyDefaultCountryCurrency) {
    return companyDefaultCountryCurrency;
  }

  logWarn(ctx, "[employee-currency] No currency found for employee", {
    currencyCode,
    employeeCountry,
    company,
  });

  return staticModels.currencies.find((currency) => currency.code === "EUR") as Currency;
};
