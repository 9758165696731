import { convertCurrency } from "~/lib/money";
import { type ExternalEmployeeForCompensationReviewEmployeeCreation } from "~/services/compensation-review/campaigns/admin/createCompensationReviewEmployee";
import { type CompensationReviewContext } from "~/services/compensation-review/compensationReviewContext";
import { computeExternalEmployeeCompensation } from "~/services/external-employee/computeCompensation";

export const computeCompensationReviewEmployeeSalaryPayload = (
  ctx: CompensationReviewContext,
  externalEmployee: ExternalEmployeeForCompensationReviewEmployeeCreation
) => {
  const { compensation: fteBaseSalary } = computeExternalEmployeeCompensation(externalEmployee, {
    measure: "baseSalary",
  });
  const { compensation: fteVariablePay } = computeExternalEmployeeCompensation(externalEmployee, {
    measure: "onTargetBonus",
  });
  const { compensation: otherBonus } = computeExternalEmployeeCompensation(externalEmployee, {
    measure: "fixedBonus",
  });

  const fteCoefficient = externalEmployee.fteDivider ?? 1;
  const baseSalary = Math.round(fteBaseSalary * fteCoefficient);
  const variablePay = Math.round(fteVariablePay * fteCoefficient);

  const convertedBaseSalary = convertCurrency(baseSalary, externalEmployee.currency, ctx.parameters.currency);
  const convertedVariablePay = convertCurrency(variablePay, externalEmployee.currency, ctx.parameters.currency);
  const convertedOtherBonus = convertCurrency(otherBonus, externalEmployee.currency, ctx.parameters.currency);

  const onTargetEarnings = baseSalary + variablePay;
  const convertedOnTargetEarnings = convertedBaseSalary + convertedVariablePay;
  const totalCash = onTargetEarnings + otherBonus;
  const convertedTotalCash = convertedOnTargetEarnings + convertedOtherBonus;

  return {
    baseSalary,
    convertedBaseSalary,
    otherBonus,
    convertedOtherBonus,
    variablePay,
    convertedVariablePay,
    onTargetEarnings,
    convertedOnTargetEarnings,
    totalCash,
    convertedTotalCash,
    fteCoefficient,
  };
};
