import { type IntegrationCustomFields } from "~/lib/hris/helpers/getMissingCustomFields";
import { compact } from "~/lib/lodash";

export const getCustomFieldNames = (integrationSettings: IntegrationCustomFields) => {
  return compact([
    integrationSettings.fteCustomFieldName,
    integrationSettings.levelCustomFieldName,
    integrationSettings.externalIdCustomFieldName,
    integrationSettings.holidayAllowanceCustomFieldName,
    integrationSettings.variableCustomFieldName,
    integrationSettings.businessUnitCustomFieldName,
    integrationSettings.locationCustomFieldName,
    integrationSettings.baseSalaryCustomFieldName,
    integrationSettings.jobCustomFieldName,
    integrationSettings.currencyCustomFieldName,
    ...(integrationSettings.additionalFieldMappings?.map(({ hrisFieldName }) => hrisFieldName) ?? []),
    ...(integrationSettings.customRemunerationItemMappings?.map(({ hrisFieldName }) => hrisFieldName) ?? []),
  ]);
};
