import { AuthenticationError } from "~/lib/errors/authenticationError";
import { type MiddlewareContext, next } from "~/lib/middleware/middleware";
import { signOut } from "~/lib/session";
import { type RequiredAuthenticatedUser } from "~/services/auth/fetchAuthenticatedUser";

export const protectRouteFromBlockedUsers = async (ctx: MiddlewareContext<{ user: RequiredAuthenticatedUser }>) => {
  if (!ctx.user.blockedAt) {
    return next(ctx);
  }

  await signOut(ctx.req);

  throw new AuthenticationError("Account blocked", {
    userId: ctx.user.id,
    redirect: {
      targetUrl: ctx.resolvedUrl,
      error: "account_blocked",
    },
  });
};
