import { type Currency, ExternalRemunerationType } from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { chain, isNil } from "~/lib/lodash";
import { getEuroCurrency } from "~/services/currency";
import { type ExternalEmployeeForSync } from "~/services/synchronization/syncExternalEmployee";
import { type ExternalRemunerationItemWithoutEmployee } from "~/services/synchronization/syncExternalEmployees";

// The actual max value is 9223372036854775807
// We use a slightly lower value to account for rounding errors
export const PG_MAX_BIGINT = 9.223372036854775e18;
export const PG_MAX_INT = 2147483647;

export const parseMoney = (money: string | number): number => {
  if (Number.isNaN(money)) {
    return 0;
  }

  const sanitizedMoney = `${money}`.replace(/\s|€|,/g, "");

  return Math.round(parseFloat(sanitizedMoney) * 100);
};

export const formatMoney = (amount: number | null | undefined, options?: { rounded?: boolean }) => {
  const rounded = options?.rounded ?? true;

  return amount ? (rounded ? Math.round(amount / 100) : amount / 100).toString() : "";
};

export const convertCurrency = <T extends number | null>(
  amount: T,
  sourceCurrency: Pick<Currency, "euroExchangeRate">,
  destinationCurrency: Pick<Currency, "euroExchangeRate">
): T => {
  if (isNil(amount)) {
    return null as T;
  }

  if (sourceCurrency.euroExchangeRate === destinationCurrency.euroExchangeRate) {
    return Math.round(Number(amount)) as T;
  }

  const euroAmount = Number(amount) / sourceCurrency.euroExchangeRate;

  return Math.round(euroAmount * destinationCurrency.euroExchangeRate) as T;
};

const hasOverflowingRemunerationTotal = (
  remunerationItems: ExternalRemunerationItemWithoutEmployee[],
  remunerationType: ExternalRemunerationType
) =>
  chain(remunerationItems)
    .filter((remunerationItem) => remunerationItem.nature.connectOrCreate?.create?.mappedType === remunerationType)
    .sumBy((remunerationItem) => remunerationItem.amount)
    .value() > PG_MAX_BIGINT;

export const pgProofRemunerationItems = async (
  ctx: AppContext,
  params: {
    externalEmployee: Pick<ExternalEmployeeForSync, "currencyId" | "id">;
    remunerationItems: ExternalRemunerationItemWithoutEmployee[];
  }
) => {
  const hasOverflowingBaseSalary = hasOverflowingRemunerationTotal(
    params.remunerationItems,
    ExternalRemunerationType.FIXED_SALARY
  );
  const hasOverflowingFixedBonus = hasOverflowingRemunerationTotal(
    params.remunerationItems,
    ExternalRemunerationType.FIXED_BONUS
  );
  const hasOverflowingVariableBonus = hasOverflowingRemunerationTotal(
    params.remunerationItems,
    ExternalRemunerationType.VARIABLE_BONUS
  );

  const hasOverflowingRemunerationItem =
    hasOverflowingBaseSalary || hasOverflowingFixedBonus || hasOverflowingVariableBonus;

  if (hasOverflowingRemunerationItem) {
    const euro = await getEuroCurrency(ctx);
    const sourceCurrency = await ctx.prisma.currency.findUniqueOrThrow({
      where: { id: params.externalEmployee.currencyId },
    });

    await ctx.prisma.externalEmployee.update({
      where: { id: params.externalEmployee.id },
      data: { currencyId: euro.id },
    });

    return params.remunerationItems.map((remunerationItem) => {
      const convertedAmount = convertCurrency(remunerationItem.amount, sourceCurrency, euro);
      remunerationItem.amount = Math.round(convertedAmount / 100) * 100;
      return remunerationItem;
    });
  }

  return params.remunerationItems;
};
