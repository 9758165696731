import { AccountCircle, ArrowBack, Balance, Edit, OpenInNew, WaterfallChart } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import { Command } from "cmdk";
import React, { useState } from "react";
import { CommandItem } from "~/components/command-palette/CommandItem";
import { useCommandPalette } from "~/components/command-palette/CommandPaletteContext";
import { filterCommandPalette } from "~/components/command-palette/FilterCommandPalette";
import { EmployeePicture } from "~/components/employee/EmployeePicture";
import { EMPLOYEE_ID_KEY, EXTERNAL_EMPLOYEE_ID_KEY } from "~/components/external-employee/ExternalEmployeePanel";
import { CompensationReview } from "~/components/ui/core/icons/CompensationReviewIcon";
import { useOpenLink } from "~/hooks/useOpenLink";
import { usePermissions } from "~/hooks/usePermissions";
import { useSession } from "~/hooks/useSession";
import { useSubscriptions } from "~/hooks/useSubscriptions";
import { trackCommandPaletteSearch } from "~/lib/external/segment/web/events";
import { useI18n } from "~/lib/i18n/useI18n";
import { type CommandPaletteEmployee } from "~/services/command-palette/fetchCommandPaletteCommands";
import { formatExternalEmployeeName } from "~/services/external-employee";
import { formatSalaryBandName } from "~/services/salary-bands/helpers/formatSalaryBandName";

type Props = {
  externalEmployee: CommandPaletteEmployee;
};

export const ExternalEmployeeCommandPalette: React.FC<Props> = ({ externalEmployee }) => {
  const { t } = useI18n();
  const { setSelectedExternalEmployee, closePalette } = useCommandPalette();
  const { user } = useSession();
  const { permissions } = usePermissions();
  const { subscriptions } = useSubscriptions();
  const hasSalaryBands = !!user?.company.defaultSalaryGridId;
  const { openLink, isMetaPressed } = useOpenLink();
  const [value, setValue] = useState<string>("");
  const [isEmpty, setIsEmpty] = useState(true);

  const showCompensationReviewGroup =
    externalEmployee.compensationReviewEmployees.length > 0 && subscriptions.CAN_ACCESS_COMPENSATION_REVIEW;

  const handleValueChange = (value: string) => {
    setValue(value);
    setIsEmpty(value === "");

    if (value) {
      void trackCommandPaletteSearch({ context: "external-employee", query: value });
    }
  };

  const handleBackspace = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Backspace" && isEmpty) {
      setSelectedExternalEmployee(null);
    }
  };

  return (
    <Command.Dialog
      loop
      open={!!externalEmployee}
      onOpenChange={() => setSelectedExternalEmployee(null)}
      filter={(_, search, keywords) => filterCommandPalette(search, keywords)}
    >
      <Stack direction="row" alignItems="center" className="-mx-2 border-b border-primary-300 px-2 pb-2">
        <IconButton className="-ml-1" size="small" tabIndex={-1} onClick={() => setSelectedExternalEmployee(null)}>
          <ArrowBack className="text-sm" />
        </IconButton>
        <Stack direction="row" alignItems="center" className="ml-1 rounded-sm border py-1 px-2">
          <EmployeePicture
            className="shrink-0"
            picture={externalEmployee.picture}
            placeholderId={externalEmployee.id}
            pictureUrl={externalEmployee.userPermissions?.user?.profilePictureUrl}
          />
          <Typography className="ml-2" variant="subtitle1">
            {formatExternalEmployeeName(externalEmployee)}
          </Typography>
        </Stack>
        <Typography variant="h3" color="text.secondary" className="mx-2">
          /
        </Typography>
        <Command.Input
          value={value}
          onValueChange={(value) => handleValueChange(value)}
          onKeyDown={handleBackspace}
          className="flex-1"
        />
      </Stack>

      <Command.List>
        <Command.Empty>{t("components.command-palette.no-results-found")}</Command.Empty>

        <Command.Group heading={t("components.command-palette.actions")}>
          <CommandItem
            label={t("components.command-palette.action")}
            keywords={[t("components.command-palette.actions"), t("components.command-palette.open-profile")]}
            Icon={AccountCircle}
            onSelect={() => {
              openLink({
                pathname: hasSalaryBands ? "/people/salary-bands" : "/people/market-data",
                query: { query: externalEmployee.externalId, [EXTERNAL_EMPLOYEE_ID_KEY]: `${externalEmployee.id}` },
              });
              closePalette();
            }}
          >
            <Typography variant="subtitle2">{t("components.command-palette.open-profile")}</Typography>
            {isMetaPressed && <OpenInNew className="text-sm text-gray-400" />}
          </CommandItem>

          {permissions.canAccessRawData && (
            <CommandItem
              label={t("components.command-palette.action")}
              keywords={[t("components.command-palette.actions"), t("components.command-palette.edit-profile")]}
              Icon={Edit}
              onSelect={() => {
                openLink({
                  pathname: "/account/integrations",
                  query: {
                    query: externalEmployee.externalId,
                    [EMPLOYEE_ID_KEY]: `${externalEmployee.id}`,
                    tab: "imported-employees",
                  },
                });
                closePalette();
              }}
            >
              <Typography variant="subtitle2">{t("components.command-palette.edit-profile")}</Typography>
              {isMetaPressed && <OpenInNew className="text-sm text-gray-400" />}
            </CommandItem>
          )}

          {permissions.canAccessMarketData && subscriptions.CAN_ACCESS_BENCHMARK && (
            <CommandItem
              label={t("components.command-palette.action")}
              keywords={[t("components.command-palette.actions"), t("components.command-palette.open-in-market-data")]}
              disabled={!externalEmployee.mappedEmployee}
              Icon={Balance}
              onSelect={() => {
                if (!externalEmployee.mappedEmployee) return;

                openLink({
                  pathname: "/market-data",
                  query: {
                    "jobs": `${externalEmployee.mappedEmployee.jobId}`,
                    "locations": `${externalEmployee.mappedEmployee.locationId}`,
                    "levels": externalEmployee.mappedEmployee.level,
                    "compare-with-company": "true",
                  },
                });
                closePalette();
              }}
            >
              <Typography variant="subtitle2">{t("components.command-palette.open-in-market-data")}</Typography>
              {!externalEmployee.mappedEmployee && (
                <Typography variant="caption" color="text.secondary">
                  {t("components.command-palette.employee-not-mapped")}
                </Typography>
              )}
              {isMetaPressed && externalEmployee.mappedEmployee && <OpenInNew className="text-sm text-gray-400" />}
            </CommandItem>
          )}

          {permissions.canAccessSalaryBands && subscriptions.CAN_ACCESS_SALARY_BANDS && (
            <CommandItem
              label={t("components.command-palette.action")}
              keywords={[t("components.command-palette.actions"), t("components.command-palette.open-in-salary-bands")]}
              disabled={!externalEmployee.salaryBand}
              Icon={(props) => <WaterfallChart {...props} style={{ rotate: "90deg" }} />}
              onSelect={() => {
                if (!externalEmployee.salaryBand || !user?.company.defaultSalaryGridId) return;

                openLink({
                  pathname: "/salary-bands/[gridId]/bands/[bandId]",
                  query: {
                    gridId: `${user.company.defaultSalaryGridId}`,
                    bandId: `${externalEmployee.salaryBand.id}`,
                  },
                });
                closePalette();
              }}
            >
              <Typography variant="subtitle2">{t("components.command-palette.open-in-salary-bands")}</Typography>
              {externalEmployee.salaryBand && externalEmployee.liveSalaryRangeEmployee ? (
                <Typography variant="caption" color="text.secondary">
                  {formatSalaryBandName(externalEmployee.salaryBand)}
                </Typography>
              ) : externalEmployee.liveSalaryRangeEmployee ? (
                <Typography variant="caption" color="text.secondary">
                  {t("components.command-palette.band-not-shared")}
                </Typography>
              ) : (
                <Typography variant="caption" color="text.secondary">
                  {t("components.command-palette.no-band")}
                </Typography>
              )}
              {isMetaPressed && externalEmployee.salaryBand && <OpenInNew className="text-sm text-gray-400" />}
            </CommandItem>
          )}
        </Command.Group>

        {showCompensationReviewGroup && (
          <Command.Group heading={t("components.command-palette.compensation-review")}>
            {externalEmployee.compensationReviewEmployees.map((compensationReviewEmployee) => (
              <CommandItem
                key={compensationReviewEmployee.id}
                label={t("components.command-palette.action")}
                keywords={[
                  t("components.command-palette.compensation-review"),
                  t("components.command-palette.open-campaign"),
                  compensationReviewEmployee.campaign.name,
                ]}
                Icon={CompensationReview}
                onSelect={() => {
                  openLink({
                    pathname: "/compensation-review/campaigns/[campaignId]/overview",
                    query: {
                      campaignId: `${compensationReviewEmployee.campaign.id}`,
                      [EXTERNAL_EMPLOYEE_ID_KEY]: `${externalEmployee.id}`,
                    },
                  });
                  closePalette();
                }}
              >
                <Typography variant="subtitle2">{t("components.command-palette.open-campaign")}</Typography>
                <Typography variant="caption" color="text.secondary">
                  {compensationReviewEmployee.campaign.name}
                </Typography>
                {isMetaPressed && <OpenInNew className="text-sm text-gray-400" />}
              </CommandItem>
            ))}
          </Command.Group>
        )}
      </Command.List>
    </Command.Dialog>
  );
};
