import { type Currency } from "@prisma/client";
import React, { createContext, useContext } from "react";
import { useStateWithSync } from "~/hooks/useStateWithSync";

type CurrencyProps = React.PropsWithChildren<{
  defaultCurrency: Currency;
}>;

type CurrencyContextType = {
  defaultCurrency: Currency;
};

const CurrencyContext = createContext<CurrencyContextType | null>(null);

export const useCurrency = () => {
  const context = useContext(CurrencyContext);

  if (!context) {
    throw new Error("useCurrency must be used within a CurrencyProvider");
  }

  return context;
};

export const CurrencyProvider: React.FC<CurrencyProps> = ({ defaultCurrency: initialDefaultCurrency, children }) => {
  const [defaultCurrency] = useStateWithSync<Currency>(initialDefaultCurrency ?? ({} as Currency));

  return <CurrencyContext.Provider value={{ defaultCurrency }}>{children}</CurrencyContext.Provider>;
};
