/**
 * Everything you need to know (and more !) about integrations is in Thee Holy Bible :
 * https://www.notion.so/figures-hr/Integrations-Bible-e574387e1c64417082dd90f34f6c4334
 */

import {
  type Company,
  type Currency,
  EmployeeMappingSkipReason,
  ExternalEmployeeSource,
  ExternalEmployeeStatus,
  ExternalRemunerationStatus,
  ExternalRemunerationType,
  IntegrationSource,
} from "@prisma/client";
import { mapSeries } from "bluebird";
import { isAfter, isBefore, isValid, parseISO, subYears } from "date-fns";
import { type ParsedUrlQueryInput } from "querystring";
import { match } from "ts-pattern";
import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { fetch } from "~/lib/fetch";
import { buildCustomBaseSalaryRemunerationItem } from "~/lib/hris/helpers/buildCustomBaseSalaryRemunerationItem";
import { buildJobPayload } from "~/lib/hris/helpers/buildJobPayload";
import { buildLevelPayload } from "~/lib/hris/helpers/buildLevelPayload";
import { buildLocationPayload } from "~/lib/hris/helpers/buildLocationPayload";
import { computeAdditionalFieldValuePayloads } from "~/lib/hris/helpers/computeAdditionalFieldValuePayloads";
import { getCustomFieldNames } from "~/lib/hris/helpers/getCustomFieldNames";
import { getEmployeeCurrency } from "~/lib/hris/helpers/getEmployeeCurrency";
import { getGender } from "~/lib/hris/helpers/getGender";
import { getMissingCustomFields, type IntegrationCustomFields } from "~/lib/hris/helpers/getMissingCustomFields";
import { getNumberOfMonth } from "~/lib/hris/helpers/getNumberOfMonth";
import { getUploadedEmployeeProfilePicture } from "~/lib/hris/helpers/getUploadedEmployeeProfilePicture";
import { mapCustomRemunerationItem } from "~/lib/hris/helpers/mapCustomRemunerationItem";
import { type StaticModels } from "~/lib/integration";
import { compact, get, has, isNumber, isString, partition } from "~/lib/lodash";
import { logWarn } from "~/lib/logger";
import { convertCurrency } from "~/lib/money";
import { buildExternalUrl } from "~/lib/url";
import { assertProps, isIn, isNotNull } from "~/lib/utils";
import { type IntegrationDiagnostic } from "~/services/synchronization/fetchCompanyIntegrationDiagnostics";
import { type EmployeeData, type IntegrationSettingsForSync } from "~/services/synchronization/syncExternalEmployees";

type Credentials = {
  domain: string;
  clientSecret: string;
  anonymous: boolean;
  variableCustomFieldFrequency: string | null;
};

// noinspection JSUnusedGlobalSymbols
export const enum BambooPayPer {
  HOUR = "Hour",
  DAY = "Day",
  WEEK = "Week",
  MONTH = "Month",
  QUARTER = "Quarter",
  YEAR = "Year",
}

export type BambooEmployee = {
  id: string;
  employeeNumber: string;
  isPhotoUploaded: "true" | "false";
  firstName?: string;
  lastName?: string;
  workEmail?: string;
  dateOfBirth?: string;
  hireDate?: string;
  gender: "Female" | "Male";
  location: string;
  country: string;
  jobTitle: string;
  payPer: BambooPayPer;
  payRate?: string;
  status: "Active" | "Inactive";
  bonusAmount: string;
  employmentHistoryStatus: string;
  supervisorId?: string;
} & Record<`custom${string}`, string>;

export type BambooBonus = {
  id: string;
  employeeId: string;
  date: string;
  amount: {
    currency: string;
    value: string;
  };
  reason: string;
};

export type BambooEmployeeWithBonus = BambooEmployee & {
  bonuses: BambooBonus[];
  historicalBonuses?: BambooBonus[];
};

type BambooField = {
  id: string;
  name: string;
  type?: "list" | "text" | "int" | string;
};

type BambooReportResponse = {
  title: string;
  fields: { id: string; type: string; name: string }[];
  employees: BambooEmployee[];
};

const bambooFetch = async (
  credentials: Credentials,
  endpoint: string,
  {
    method = "POST",
    query,
    body,
  }: {
    method?: "POST" | "GET";
    query?: ParsedUrlQueryInput;
    body?: Record<string, unknown>;
  } = {}
) => {
  const baseUrl = `https://api.bamboohr.com/api/gateway.php/${credentials.domain}/v1/${endpoint}/`;

  // The auth header is a base 64 encode of `username:password`
  // where username = your API key and password = any arbitrary string
  const authHeader = Buffer.from(`${credentials.clientSecret}:beltalowda`).toString("base64");

  const res = await fetch(buildExternalUrl(baseUrl, query), {
    method,
    headers: {
      Accept: "application/json",
      Authorization: `Basic ${authHeader}`,
      ...(body && { "Content-Type": "application/json" }),
    },
    ...(body && { body: JSON.stringify(body) }),
  });

  if (!res.ok) {
    throw new Error(`[bamboo] ${res.status} ${res.statusText} : ${res.headers.get("x-bamboohr-error-message")}`);
  }

  return res;
};

const getBambooProfilePicture = async (
  ctx: AppContext,
  options: { integrationSettings: SafeIntegrationSettings; apiEmployee: BambooEmployeeWithBonus }
) => {
  if (options.apiEmployee.isPhotoUploaded !== "true") {
    return undefined;
  }

  return getUploadedEmployeeProfilePicture(ctx, {
    apiEmployeeId: options.apiEmployee.id,
    source: IntegrationSource.BAMBOO,
    integrationSettings: options.integrationSettings,
    fetch: () =>
      bambooFetch(options.integrationSettings, `employees/${options.apiEmployee.id}/photo/xs`, { method: "GET" }),
  });
};

const getCustomField = (apiEmployee: BambooEmployeeWithBonus, fieldId: string | null) => {
  if (!fieldId) {
    return null;
  }

  const customField = get(apiEmployee, fieldId);

  if (!(isString(customField) || isNumber(customField))) {
    return null;
  }

  return customField.toString();
};

export const mapBambooEmployee = async (
  ctx: AppContext,
  company: Company,
  apiEmployee: BambooEmployeeWithBonus,
  integrationSettings: SafeIntegrationSettings,
  staticModels: StaticModels,
  ignoreProfilePicture?: boolean
): Promise<EmployeeData> => {
  // Handle custom fields
  const {
    fteCustomFieldName,
    levelCustomFieldName,
    baseSalaryCustomFieldName,
    baseSalaryCustomFieldFrequency,
    variableCustomFieldName,
    variableCustomFieldFrequency,
    externalIdCustomFieldName,
    holidayAllowanceCustomFieldName,
    businessUnitCustomFieldName,
    locationCustomFieldName,
    jobCustomFieldName,
    currencyCustomFieldName,
    additionalFieldMappings = [],
    customRemunerationItemMappings = [],
  } = integrationSettings;

  const customFte = getCustomField(apiEmployee, fteCustomFieldName);
  const customLevel = getCustomField(apiEmployee, levelCustomFieldName);
  const customEmployeeNumber = getCustomField(apiEmployee, externalIdCustomFieldName);
  const customBaseSalary = getCustomField(apiEmployee, baseSalaryCustomFieldName);
  const customVariable = getCustomField(apiEmployee, variableCustomFieldName);
  const holidayAllowanceValue = getCustomField(apiEmployee, holidayAllowanceCustomFieldName);
  const businessUnit = getCustomField(apiEmployee, businessUnitCustomFieldName);
  const customLocation = getCustomField(apiEmployee, locationCustomFieldName);
  const customJob = getCustomField(apiEmployee, jobCustomFieldName);
  const customCurrency = getCustomField(apiEmployee, currencyCustomFieldName);
  const additionalFieldValues = additionalFieldMappings.map(({ hrisFieldName, additionalFieldId, id }) => ({
    additionalFieldId,
    additionalFieldMappingId: id,
    value: getCustomField(apiEmployee, hrisFieldName),
  }));

  const customRemunerationItemsValues = customRemunerationItemMappings.map((customRemunerationItemMapping) => ({
    ...customRemunerationItemMapping,
    value: getCustomField(apiEmployee, customRemunerationItemMapping.hrisFieldName),
  }));

  const country = value(() => {
    if (!apiEmployee.country) {
      return null;
    }
    return staticModels.countries.find(({ name }) => name.startsWith(apiEmployee.country));
  });

  const currency = getEmployeeCurrency(ctx, {
    staticModels,
    // There is not a real currency code field in the API
    // We need to extract it from the payRate field
    // Here is an example of the payRate field : `82000.00 USD`
    currencyCode: customCurrency ?? apiEmployee.payRate?.split(" ")[1],
    employeeCountry: country,
    company,
  });

  const job = buildJobPayload({
    companyId: company.id,
    name: customJob ?? apiEmployee.jobTitle ?? null,
  });

  const location = buildLocationPayload({
    companyId: company.id,
    externalId: customLocation ?? apiEmployee.location ?? `${country?.id}`,
    name: customLocation ?? apiEmployee.location ?? `${country?.name}`,
    countryId: country?.id ?? null,
  });

  const level = buildLevelPayload({
    companyId: company.id,
    name: customLevel ?? null,
  });

  const hireDate =
    apiEmployee.hireDate && isValid(parseISO(apiEmployee.hireDate)) ? parseISO(apiEmployee.hireDate) : null;
  const birthDate =
    apiEmployee.dateOfBirth && isValid(parseISO(apiEmployee.dateOfBirth)) ? parseISO(apiEmployee.dateOfBirth) : null;

  // If the employee is not a permanent, we create them with a SKIPPED status
  const isAutoSkipped =
    has(apiEmployee, "employmentHistoryStatus") && !isPermanentFulltime(apiEmployee.employmentHistoryStatus);

  const input: EmployeeData["input"] = {
    source: ExternalEmployeeSource.BAMBOO,
    externalId: apiEmployee.id,
    status: isAutoSkipped ? ExternalEmployeeStatus.SKIPPED : ExternalEmployeeStatus.UNMAPPED,
    mappingSkipReason: isAutoSkipped ? EmployeeMappingSkipReason.NOT_PERMANENT_EMPLOYEE : null,
    firstName: apiEmployee.firstName,
    lastName: apiEmployee.lastName,
    email: apiEmployee.workEmail,
    employeeNumber: customEmployeeNumber ? customEmployeeNumber : apiEmployee.employeeNumber,
    gender: getGender({
      value: apiEmployee.gender,
      options: {
        female: "Female",
        male: "Male",
      },
    }),
    hireDate,
    birthDate,
    company: {
      connect: { id: company.id },
    },
    currency: {
      connect: { code: currency.code },
    },
    ...location,
    ...job,
    ...level,
    ...(businessUnit && { businessUnit }),
  };

  const remunerationItems: EmployeeData["remunerationItems"] = [];

  const numberMonths = getNumberOfMonth({
    externalId: location?.location?.connectOrCreate?.create?.externalId,
    additionalMonthRules: staticModels.additionalMonthRules,
    externalLocations: staticModels.externalLocations,
  });

  const customBaseSalaryRemunerationItem = buildCustomBaseSalaryRemunerationItem({
    baseSalaryCustomFieldFrequency,
    company,
    customBaseSalary,
    numberMonths,
    source: ExternalEmployeeSource.BAMBOO,
  });

  // Get Fixed salary
  if (customBaseSalaryRemunerationItem) {
    remunerationItems.push(customBaseSalaryRemunerationItem);
  } else {
    const defaultBaseSalary = mapBambooBaseSalary(ctx, {
      company,
      apiEmployee,
      numberMonths,
    });
    if (defaultBaseSalary) {
      remunerationItems.push(defaultBaseSalary);
    }
  }

  if (customVariable && variableCustomFieldFrequency) {
    const multiplier = match(variableCustomFieldFrequency)
      .with("MONTHLY", () => 12)
      .with("QUARTERLY", () => 4)
      .with("YEARLY", () => 1)
      .exhaustive();

    const amount = value(() => {
      const baseAmount = parseInt(customVariable) * multiplier;

      if (apiEmployee.customVariableCurrency === currency.code) {
        return baseAmount;
      }

      const bonusCurrency = staticModels.currencies.find(
        (currency) => currency.code === apiEmployee.customVariableCurrency
      );

      if (!bonusCurrency) {
        return baseAmount;
      }

      return convertCurrency(baseAmount, bonusCurrency, currency);
    });

    if (!!amount) {
      remunerationItems.push({
        company: {
          connect: { id: company.id },
        },
        source: ExternalEmployeeSource.BAMBOO,
        externalId: "variable-bonus",
        amount: Math.round(amount * 100),
        status: ExternalRemunerationStatus.LIVE,
        nature: {
          connectOrCreate: {
            where: {
              companyId_source_externalId: {
                companyId: company.id,
                source: ExternalEmployeeSource.BAMBOO,
                externalId: "variable-bonus",
              },
            },
            create: {
              source: ExternalEmployeeSource.BAMBOO,
              externalId: "variable-bonus",
              name: "Variable bonus",
              mappedType: "VARIABLE_BONUS",
              company: {
                connect: {
                  id: company.id,
                },
              },
            },
          },
        },
      } satisfies EmployeeData["remunerationItems"][number]);
    }
  } else {
    remunerationItems.push(
      ...compact([
        ...apiEmployee.bonuses.map((bonus) =>
          mapBambooBonus(ctx, {
            company,
            bonus,
            mainCurrency: currency,
            currencies: staticModels.currencies,
            externalRemunerationStatus: ExternalRemunerationStatus.LIVE,
          })
        ),
        ...(apiEmployee.historicalBonuses ?? []).map((bonus) =>
          mapBambooBonus(ctx, {
            company,
            bonus,
            mainCurrency: currency,
            currencies: staticModels.currencies,
            externalRemunerationStatus: ExternalRemunerationStatus.HISTORICAL,
          })
        ),
      ])
    );
  }

  if (customRemunerationItemsValues.length > 0) {
    const customItems = compact(
      customRemunerationItemsValues.map((customRemunerationItem) =>
        mapCustomRemunerationItem(integrationSettings, customRemunerationItem)
      )
    );
    remunerationItems.push(...customItems);
  }

  const ignoreFte = apiEmployee.payPer === BambooPayPer.HOUR || apiEmployee.payPer === BambooPayPer.DAY;

  return {
    input,
    profilePicture: !ignoreProfilePicture
      ? await getBambooProfilePicture(ctx, { integrationSettings, apiEmployee })
      : undefined,
    remunerationItems,
    additionalFieldValues: computeAdditionalFieldValuePayloads(company, additionalFieldValues),
    managerExternalId: apiEmployee.supervisorId,
    holidayAllowanceValue,
    ...(customFte && { fte: customFte, ignoreFte }),
  };
};

const mapBambooBaseSalary = (
  ctx: AppContext,
  params: {
    company: Company;
    apiEmployee: BambooEmployeeWithBonus;
    numberMonths: number;
  }
): EmployeeData["remunerationItems"][number] | null => {
  const { company, apiEmployee, numberMonths } = params;

  // The payRate is expressed with currency code like this : `82000.00 USD`
  const payRate = value(() => {
    if (!apiEmployee.payRate) {
      return null;
    }
    const [amount] = apiEmployee.payRate.split(" ");
    return amount || null;
  });

  if (!payRate) {
    return null;
  }

  const amount = parseFloat(payRate);

  if (amount <= 0) {
    return null;
  }

  const interval = apiEmployee.payPer;

  if (!["Hour", "Day", "Month", "Quarter", "Year"].includes(interval)) {
    logWarn(ctx, `[sync] Unhandled Bamboo fix salary interval`, { interval });
  }

  const numberOfMonthMultiplier = numberMonths / 12;

  const multiplier = value(() => {
    if (interval === "Hour") {
      return 40 * 52 * numberOfMonthMultiplier;
    }

    if (interval === "Day") {
      return 5 * 52 * numberOfMonthMultiplier;
    }

    if (interval === "Month") {
      return 12 * numberOfMonthMultiplier;
    }

    if (interval === "Quarter") {
      return 4 * numberOfMonthMultiplier;
    }

    return 1;
  });

  const yearlySalary = Math.round(amount * multiplier * 100);

  return {
    company: {
      connect: { id: company.id },
    },
    source: ExternalEmployeeSource.BAMBOO,
    externalId: "fix-salary",
    amount: yearlySalary,
    numberMonths,
    status: ExternalRemunerationStatus.LIVE,
    nature: {
      connectOrCreate: {
        where: {
          companyId_source_externalId: {
            companyId: company.id,
            source: ExternalEmployeeSource.BAMBOO,
            externalId: "fix-salary",
          },
        },
        create: {
          source: ExternalEmployeeSource.BAMBOO,
          externalId: "fix-salary",
          name: "Fixed salary",
          mappedType: ExternalRemunerationType.FIXED_SALARY,
          company: {
            connect: {
              id: company.id,
            },
          },
        },
      },
    },
  } satisfies EmployeeData["remunerationItems"][number];
};

const mapBambooBonus = (
  ctx: AppContext,
  params: {
    company: Company;
    bonus: BambooBonus;
    mainCurrency: Currency;
    currencies: Currency[];
    externalRemunerationStatus: ExternalRemunerationStatus;
  }
): EmployeeData["remunerationItems"][number] | null => {
  const { company, bonus, currencies, mainCurrency, externalRemunerationStatus } = params;

  const bonusAmount = value(() => {
    if (!bonus.amount.value) {
      return null;
    }

    const baseAmount = parseInt(bonus.amount.value);

    if (bonus.amount.currency === mainCurrency.code) {
      return baseAmount;
    }

    const bonusCurrency = currencies.find((currency) => currency.code === bonus.amount.currency);

    if (!bonusCurrency || !mainCurrency) {
      logWarn(ctx, "[bamboo] Unknown currency", {
        companyId: company.id,
        main: mainCurrency.code,
        bonus: bonus.amount.currency,
      });
      return baseAmount;
    }

    return convertCurrency(baseAmount, bonusCurrency, mainCurrency);
  });

  if (!bonusAmount) {
    return null;
  }

  const externalId = match(externalRemunerationStatus)
    .with(ExternalRemunerationStatus.LIVE, () => `variable-bonus-${bonus.reason}-${bonus.id}`)
    .with(ExternalRemunerationStatus.HISTORICAL, () => `variable-bonus-historical-${bonus.reason}-${bonus.id}`)
    .exhaustive();

  return {
    company: {
      connect: { id: company.id },
    },
    source: ExternalEmployeeSource.BAMBOO,
    externalId,
    amount: Math.round(bonusAmount * 100),
    status: externalRemunerationStatus,
    date: bonus.date ? parseISO(bonus.date) : null,
    reason: bonus.reason,
    nature: {
      connectOrCreate: {
        where: {
          companyId_source_externalId: {
            companyId: company.id,
            source: ExternalEmployeeSource.BAMBOO,
            externalId: bonus.reason,
          },
        },
        create: {
          source: ExternalEmployeeSource.BAMBOO,
          externalId: bonus.reason,
          name: bonus.reason,
          mappedType: ExternalRemunerationType.VARIABLE_BONUS,
          company: {
            connect: {
              id: company.id,
            },
          },
        },
      },
    },
  } satisfies EmployeeData["remunerationItems"][number];
};

const anonymise = (employee: BambooEmployee): BambooEmployee => {
  delete employee.firstName;
  delete employee.lastName;
  delete employee.dateOfBirth;
  delete employee.workEmail;
  return employee;
};

const getEmployeeBonuses = async (credentials: Credentials, employeeId: string) => {
  const res = await bambooFetch(credentials, `employees/${employeeId}/tables/bonus`, { method: "GET" });

  const json: BambooBonus[] = await res.json();

  return json;
};

const isPermanentFulltime = (employmentStatus: string) => {
  // The key is present but contains null, keep it
  if (!employmentStatus) {
    return true;
  }

  const forbiddenTokens = [
    "Terminated",
    "Freelancer",
    "Leave",
    "Mini Job",
    "Part Time",
    "Part-Time",
    "Longtime sickness",
    "Elternzeit",
    "Contractor",
    "Teilzeit",
    "Apprentice",
    "Intern",
    "Test Record",
    "ProntoPro (detachment letter)",
    "EOR",
    "VIE",
    "Fixed-Term",
    "Fixed Term",
    "Outsourced",
    "Inactive",
    "Thesis worker",
    "Temporary",
    "Student",
    "Exempted from work",
    "Non-Employee",
    "Consultant",
    "Trainee",
  ];

  const words = employmentStatus.toLowerCase().split(/[\W_]/);

  if (forbiddenTokens.some((token) => words.includes(token.toLowerCase()))) {
    return false;
  }

  return true;
};

/**
 *
 * Resolving custom fields is done in 4 steps.
 * 1. The custom field is stored in IntegrationSettings with its numerical ID. We get the human readable name from the meta/fields endpoint.
 * 2. Then we resolve this human readable name to the actual property name from the fields section of the reports/custom endpoint
 * 3. Finally we get the value from the employee data from reports/custom
 * 4. To simplify later operations this method simply adds a property named with the numerical ID to the employee data :
 * {
 *   "id": "5678",
 *   ...
 *   "customLevel": "Expert",
 *   "1234": "Expert", <=== here
 * }
 */
const resolveCustomFields = (
  credentials: BambooIntegrationSettingsInput,
  queriedFields: BambooReportResponse["fields"],
  completeFieldList: BambooField[]
) => {
  return (employee: BambooEmployee) => {
    getCustomFieldNames(credentials)
      .filter(isNotNull)
      .forEach((fieldId) => {
        const fieldMeta = completeFieldList.find((field) => field.id === fieldId);

        if (!fieldMeta) {
          return;
        }

        const fieldSpec = queriedFields.find((spec) => spec.name === fieldMeta.name);
        const humanReadableName = fieldSpec?.id;

        if (!humanReadableName) {
          return;
        }

        const fieldValue = value(() => {
          const value = employee[humanReadableName as `custom${string}`];

          if (!value) {
            return null;
          }

          if (fieldSpec.type !== "currency") {
            return value;
          }

          const [amount, currency] = value.split(" ");

          if (!amount || !currency) {
            return null;
          }

          employee["customVariableCurrency"] = currency;

          return amount;
        });

        if (!fieldValue) {
          return;
        }

        employee[fieldId as `custom${string}`] = fieldValue;
      });
    return employee;
  };
};

export const getBambooEmployees = async (
  credentials: BambooIntegrationSettingsInput
): Promise<BambooEmployeeWithBonus[]> => {
  const res = await bambooFetch(credentials, "reports/custom", {
    query: { format: "json", onlyCurrent: "true" },
    body: {
      fields: compact([
        "id",
        "employeeNumber",
        "isPhotoUploaded",
        "firstName",
        "lastName",
        "workEmail",
        "dateOfBirth",
        "hireDate",
        "gender",
        "location",
        "country",
        "jobTitle",
        "paidPer",
        "payRate",
        "status",
        "bonusAmount",
        "employmentHistoryStatus",
        "supervisorId",
        ...getCustomFieldNames(credentials),
      ]),
    },
  });

  const json: BambooReportResponse = await res.json();

  const queriedFields = json.fields;
  const completeFieldList = await getBambooCustomFields(credentials);

  const activeEmployees = json.employees
    .filter((employee) => {
      if (has(employee, "status") && employee.status !== "Active") {
        return false;
      }
      return true;
    })
    .map((employee) => {
      return credentials.anonymous ? anonymise(employee) : employee;
    })
    .map(resolveCustomFields(credentials, queriedFields, completeFieldList));

  const hasCustomBonus = credentials.variableCustomFieldName && credentials.variableCustomFieldFrequency;

  return mapSeries(activeEmployees, async (apiEmployee) => {
    //fix for managers
    if (apiEmployee.supervisorId) {
      const manager = activeEmployees.find((employee) => employee.employeeNumber === apiEmployee.supervisorId);
      if (manager) {
        apiEmployee.supervisorId = manager.id;
      } else {
        apiEmployee.supervisorId = undefined;
      }
    }

    if (!apiEmployee.bonusAmount || hasCustomBonus) {
      return { ...apiEmployee, bonuses: [] };
    }

    // apiEmployee.bonusAmount contains the latest bonus for this employee, in the format "10.000 USD"
    const [bonusAmount, bonusCurrency] = apiEmployee.bonusAmount.split(" ");

    // If it is empty, it means there are no bonuses in the past for this employee
    if (!bonusAmount) {
      return { ...apiEmployee, bonuses: [] };
    }

    // If it is not empty, we fetch the bonuses from a different endpoint
    const allBonuses = await getEmployeeBonuses(credentials, apiEmployee.id);

    // And keep only those for the past year
    const [bonuses, historicalBonuses] = partition(
      allBonuses,
      (bonus) => isBefore(parseISO(bonus.date), new Date()) && isAfter(parseISO(bonus.date), subYears(new Date(), 1))
    );

    return {
      ...apiEmployee,
      historicalBonuses,
      bonuses: bonuses.length
        ? bonuses
        : [
            {
              id: `bonus-amount-${apiEmployee.id}`,
              employeeId: apiEmployee.id,
              date: null,
              amount: {
                currency: bonusCurrency,
                value: bonusAmount,
              },
              reason: "Bonus amount from employee",
            },
          ],
    };
  }) as Promise<BambooEmployeeWithBonus[]>;
};

export const getBambooCustomFields = async (credentials: Credentials): Promise<BambooField[]> => {
  const res = await bambooFetch(credentials, "meta/fields", { method: "GET" });
  const customFields: Array<BambooField> = await res.json();

  return customFields
    .filter((field) => isIn(field.type, ["list", "int", "text", "currency"]))
    .map((field) => ({ id: field.id.toString(), name: field.name }));
};

const assertSafeIntegrationSettings = (integrationSettings: IntegrationSettingsForSync) =>
  assertProps(integrationSettings, ["domain", "clientSecret"]);

export type SafeIntegrationSettings = ReturnType<typeof assertSafeIntegrationSettings>;

export const getMappedBambooEmployees = async (
  ctx: AppContext,
  company: Company,
  integrationSettings: IntegrationSettingsForSync,
  staticModels: StaticModels,
  ignoreProfilePicture = false
): Promise<EmployeeData[]> => {
  const safeIntegrationSettings = assertSafeIntegrationSettings(integrationSettings);

  const bambooEmployees = await getBambooEmployees(safeIntegrationSettings);

  return mapSeries(bambooEmployees, (bambooEmployee) =>
    mapBambooEmployee(ctx, company, bambooEmployee, safeIntegrationSettings, staticModels, ignoreProfilePicture)
  );
};

export type BambooIntegrationSettingsInput = IntegrationSettingsForSync & Credentials & IntegrationCustomFields;

export const getBambooDiagnostic = async (
  ctx: AppContext,
  input: BambooIntegrationSettingsInput
): Promise<IntegrationDiagnostic> => {
  try {
    const res = await bambooFetch(input, "reports/custom", {
      query: { format: "json", onlyCurrent: "true" },
      body: {
        fields: ["id"],
      },
    });

    const { employees }: BambooReportResponse = await res.json();

    if (!employees.length) {
      return {
        connection: false,
        connectionError: "We could not find any employees within your Bamboo HR account",
        missingFields: [],
        availableFields: [],
      };
    }

    const availableFields = await getBambooCustomFields(input);

    const missingFields = getMissingCustomFields(input, availableFields);

    return {
      connection: true,
      connectionError: "",
      missingFields,
      availableFields,
    };
  } catch (error) {
    return {
      connection: false,
      connectionError: error.message,
      missingFields: [],
      availableFields: [],
    };
  }
};

export const getRawBambooEmployees = async (
  ctx: AppContext,
  company: Company,
  integrationSettings: IntegrationSettingsForSync
): Promise<BambooEmployee[]> => {
  const safeIntegrationSettings = assertSafeIntegrationSettings(integrationSettings);

  return getBambooEmployees(safeIntegrationSettings);
};
