import { value } from "~/components/helpers";

type LocationParams = {
  companyId: number;
  externalId: string | null;
  name: string | null;
  countryId?: number | null;
  countryCode?: string | null;
  mappedLocationId?: number | null;
};

export const buildLocationPayload = (params: LocationParams) => {
  const { companyId, externalId, name, countryId, countryCode, mappedLocationId } = params;

  if (!externalId || !name) {
    return null;
  }

  const country = value(() => {
    if (!countryCode && !countryId) {
      return null;
    }

    if (countryId) {
      return { country: { connect: { id: countryId } } };
    }

    if (countryCode) {
      return {
        country: {
          connect: { alpha2: countryCode },
        },
      };
    }
  });

  return {
    location: {
      connectOrCreate: {
        where: {
          companyId_externalId: {
            companyId,
            externalId,
          },
        },
        create: {
          externalId,
          name,
          autoMappingEnabled: true,
          company: {
            connect: { id: companyId },
          },
          ...country,
          ...(mappedLocationId && { mappedLocation: { connect: { id: mappedLocationId } } }),
        },
      },
    },
  };
};
