import { UserRole } from "@prisma/client";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { type AuthenticatedUser } from "~/lib/session";
import { type FetchCampaignResult } from "~/services/compensation-review/campaigns/fetchCampaign";
import { type CompensationReviewCampaignContext } from "~/services/compensation-review/compensationReviewContext";
import { type FetchAuthenticatedCompensationReviewReviewerResult } from "~/services/compensation-review/fetchAuthenticatedCompensationReviewReviewer";
import { type FetchOffCycleReviewConfigurationResult } from "~/services/compensation-review/off-cycle-reviews/fetchOffCycleReviewConfiguration";
import { getCompensationReviewBudget } from "~/services/compensation-review/shared/compensationReviewBudget";

type CampaignCheck = (params: {
  user: AuthenticatedUser;
  campaign: FetchCampaignResult;
  authenticatedReviewer: FetchAuthenticatedCompensationReviewReviewerResult;
  impersonation?: AppContext["impersonation"];
}) => boolean;

type OffCycleCheck = (params: {
  user: AuthenticatedUser;
  configuration: FetchOffCycleReviewConfigurationResult;
}) => boolean;

const isCampaignAdministrator: CampaignCheck = (params) => {
  return (
    params.campaign.admins.map(({ userId }) => userId).includes(params.user.id) ||
    params.user.permissions.role === UserRole.ADMIN ||
    params.user?.isSuperAdmin
  );
};

export const canSeeYourReviews: CampaignCheck = (params) => {
  return !!params.authenticatedReviewer?.recommendationsCount;
};

export const canAccessConfiguration: CampaignCheck = (params) => {
  return isCampaignAdministrator(params);
};

export const canAccessYourReviews: CampaignCheck = (params) => {
  if (!canSeeYourReviews(params)) return false;

  if (params.campaign.isSandbox) {
    return !!params.impersonation?.user;
  }

  return params.campaign.isInProgress || params.campaign.isClosed;
};

export const canSeeOverview: CampaignCheck = (params) => {
  return isCampaignAdministrator(params);
};

export const canAccessOverview: CampaignCheck = (params) => {
  return canSeeOverview(params) && !params.campaign.isConfiguring;
};

export const canSeeReviewers: CampaignCheck = (params) => {
  return isCampaignAdministrator(params);
};

export const canAccessReviewers: CampaignCheck = (params) => {
  return canSeeReviewers(params) && !params.campaign.isConfiguring;
};

export const canSeeAnalytics: CampaignCheck = (params) => {
  return isCampaignAdministrator(params) || !!params.authenticatedReviewer?.canSeeAnalytics;
};

export const canAccessAnalytics: CampaignCheck = (params) => {
  return canSeeAnalytics(params) && !params.campaign.isConfiguring;
};

export const canViewBudget = (ctx: CompensationReviewCampaignContext, params: { budgetId: number }) => {
  const checkContext = {
    user: ctx.user,
    campaign: ctx.campaign,
    authenticatedReviewer: ctx.authenticatedReviewer,
    impersonation: ctx.impersonation,
  };

  if (ctx.campaign.isConfiguring) return false;

  if (isCampaignAdministrator(checkContext)) return true;

  const budget = getCompensationReviewBudget(ctx, params.budgetId);
  if (budget.isAdminBudget) return false;

  const permission = ctx.authenticatedReviewer?.budgetPermissions.find(
    (permission) => permission.budgetId === params.budgetId
  );

  if (!permission) return false;

  return !!permission.canView;
};

export const canAllocateBudget = (ctx: CompensationReviewCampaignContext, params: { budgetId: number }) => {
  const checkContext = {
    user: ctx.user,
    campaign: ctx.campaign,
    authenticatedReviewer: ctx.authenticatedReviewer,
    impersonation: ctx.impersonation,
  };

  if (ctx.campaign.isConfiguring) return false;

  if (isCampaignAdministrator(checkContext)) return true;

  const permission = ctx.authenticatedReviewer?.budgetPermissions.find(
    (permission) => permission.budgetId === params.budgetId
  );

  if (!permission) return false;

  return !!permission.canAllocate;
};

export const canConfigureCampaign: CampaignCheck = (params) => {
  const devOverride = config.app.isLocal && params.user.isSuperAdmin;
  if (devOverride) return true;

  return canAccessConfiguration(params) && (params.campaign.isConfiguring || params.campaign.isSandbox);
};

export const canConfigureAfterLaunch: CampaignCheck = (params) => {
  return canAccessConfiguration(params) && !params.campaign.isConfiguring;
};

export const canReviewCampaign: CampaignCheck = (params) => {
  return canSeeYourReviews(params) && (params.campaign.isSandbox || params.campaign.isInProgress);
};

export const canSetCurrentReviewer: CampaignCheck = (params) => {
  const isCampaignAdmin = isCampaignAdministrator(params);
  const isSuperAdmin = params.user.isSuperAdmin;
  const reviewersCanSetCurrentReviewer = params.campaign.allowReviewersToSetCurrentReviewer;
  const canReview = canReviewCampaign(params);

  if (isCampaignAdmin || isSuperAdmin) {
    return true;
  }

  return canReview && reviewersCanSetCurrentReviewer;
};

export const canUpdateReviewers: CampaignCheck = (params) => {
  return isCampaignAdministrator(params);
};

export const canBypassRules: CampaignCheck = (params) => {
  return isCampaignAdministrator(params);
};

const isOffCycleAdministrator: OffCycleCheck = (params) => {
  return (
    params.configuration.admins.map(({ userId }) => userId).includes(params.user.id) ||
    params.user.permissions.role === UserRole.ADMIN ||
    params.user?.isSuperAdmin
  );
};

export const canAccessOffCycleConfiguration: OffCycleCheck = (params) => {
  return isOffCycleAdministrator(params);
};

export const canAccessOffCycleOverview: OffCycleCheck = (params) => {
  return isOffCycleAdministrator(params);
};

export const canSkipOffCycleReviewersRecommendation: OffCycleCheck = (params) => {
  return isOffCycleAdministrator(params) && params.configuration.allowReviewersToSetCurrentReviewer;
};

export const canUpdateOffCycleRecommendation: OffCycleCheck = (params) => {
  return isOffCycleAdministrator(params);
};

export const canBypassOffCycleRules: OffCycleCheck = (params) => {
  return isOffCycleAdministrator(params);
};

export const canApproveOffCycleReview: OffCycleCheck = (params) => {
  return isOffCycleAdministrator(params);
};
