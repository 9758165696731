import type { Prisma } from "@prisma/client";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { BusinessLogicError } from "~/lib/errors/businessLogicError";
import { dangerouslyIncludeHistoricalExternalRemunerationItems } from "~/lib/prisma-restrictions/schemas/generateExternalRemunerationItemHistoricalSchema";
import { assertNotNil } from "~/lib/utils";
import { type FetchExternalEmployeeForPanelInput } from "~/pages/api/external-employee/fetch-external-employee-for-panel";
import { externalEmployeeFieldsSelectForDisplay } from "~/services/additional-field/getAllAdditionalFields";
import { externalEmployeeSelectForDisplay } from "~/services/compensation-review/campaigns/admin/fetchCompensationReviewEmployees";
import { fetchCompensationReviewCampaignContext } from "~/services/compensation-review/compensationReviewContext";
import { CurrentOffCycleReviewRequestStatuses } from "~/services/compensation-review/off-cycle-reviews/offCycleReviewRequestStatus";

export const externalEmployeePanelSelect = {
  ...externalEmployeeSelectForDisplay,
  ...externalEmployeeFieldsSelectForDisplay,
  offCycleReviewRequests: {
    where: {
      status: { in: CurrentOffCycleReviewRequestStatuses },
    },
    select: {
      id: true,
      status: true,
    },
  },
  mappedEmployee: {
    select: {
      baseSalary: true,
      onTargetBonus: true,
      fixedBonus: true,
      currency: {
        select: {
          code: true,
          euroExchangeRate: true,
          decimals: true,
        },
      },
    },
  },
  currency: true,
  remunerationItems: {
    orderBy: [
      {
        date: {
          sort: "desc",
          nulls: "last",
        },
      },
      { amount: "desc" },
    ],
    select: {
      amount: true,
      date: true,
      status: true,
      nature: {
        select: {
          name: true,
          mappedType: true,
        },
      },
    },
  },
  job: {
    select: {
      name: true,
    },
  },
  level: {
    select: {
      name: true,
    },
  },
  location: {
    select: {
      name: true,
      country: {
        select: {
          name: true,
          alpha2: true,
        },
      },
    },
  },
} satisfies Prisma.ExternalEmployeeSelect;

export type ExternalEmployeePanelSelect = Prisma.ExternalEmployeeGetPayload<{
  select: typeof externalEmployeePanelSelect;
}>;

export const fetchExternalEmployeeForPanel = async (ctx: AppContext, input: FetchExternalEmployeeForPanelInput) => {
  if (!!input.fromCompensationReviewCampaignId) {
    const compensationReviewContext = await fetchCompensationReviewCampaignContext(ctx, {
      campaignId: input.fromCompensationReviewCampaignId,
    });
    const authenticatedReviewerId = compensationReviewContext.authenticatedReviewer?.id;

    if (!authenticatedReviewerId && !compensationReviewContext.permissions.canAccessOverview) {
      throw new BusinessLogicError("You are not allowed to access this compensation review employee");
    }

    const compensationReviewEmployee = await ctx.prisma.compensationReviewEmployee.findFirstOrThrow({
      ...dangerouslyIncludeHistoricalExternalRemunerationItems(),
      where: {
        externalEmployeeId: input.id,
        ...(!compensationReviewContext.permissions.canAccessOverview && {
          recommendations: { some: { reviewerId: assertNotNil(authenticatedReviewerId) } },
        }),
      },
      select: {
        externalEmployee: { select: externalEmployeePanelSelect },
      },
    });

    return compensationReviewEmployee.externalEmployee;
  }

  return ctx.prisma.externalEmployee.findFirstOrThrow({
    ...dangerouslyIncludeHistoricalExternalRemunerationItems(),
    where: { id: input.id },
    select: externalEmployeePanelSelect,
  });
};

export type FetchExternalEmployeeForPanelResult = AsyncReturnType<typeof fetchExternalEmployeeForPanel>;
