import { type CompensationReviewSalaryPeriod, type MarketDataSearchSource, type OnboardingStep } from "@prisma/client";
import { type NextRouter } from "next/router";
import { trackClientEvent, trackClientPage } from "~/lib/external/segment/web/client";
import { type Input as MarketDataInput } from "~/pages/api/get-market-data-stats";
import { type AdjustedChartId, type UnadjustedChartId } from "~/services/analytics/gender-pay-gap-report/utils";
import { type EmployeeLocationWithCountry } from "~/services/employee/employeeLocation";

export const trackPageLoaded = async (event: { path: string }) => {
  await trackClientPage(event.path, {
    url: window.location.href,
  });
};

export const trackModalOpened = async (event: { name: string }) => {
  await trackClientEvent("Modal Opened", {
    name: event.name,
    url: window.location.href,
  });
};

export const trackFiltersUsed = async (event: { page: string; queryParams: Record<string, string | undefined> }) => {
  await trackClientEvent("Filters Used", {
    page: event.page,
    queryParams: event.queryParams,
    url: window.location.href,
  });
};

export const trackSidePanelOpened = async (event: { name: string; router: NextRouter; externalEmployeeId: number }) => {
  await trackClientEvent("Side Panel Opened", {
    name: event.name,
    externalEmployeeId: event.externalEmployeeId,
    url: event.router.asPath,
    query: event.router.query,
  });
};

export const trackInteraction = async (event: {
  label: string;
  tagName: string;
  href?: string;
  router: NextRouter;
}) => {
  await trackClientEvent("Element Interacted", {
    label: event.label,
    tagName: event.tagName,
    href: event.href,
    path: event.router.pathname,
    fullPath: event.router.asPath,
    query: event.router.query,
  });
};

export const trackMarketDataSearched = async (event: {
  input: MarketDataInput;
  origin: "INITIAL_SEARCH" | "INPUT_UPDATE" | MarketDataSearchSource;
}) => {
  await trackClientEvent("Market Data Searched", {
    origin: event.origin,
    input: event.input,
  });
};

export const trackMarketDataSearchShared = async (event: { mode: "COPY_TO_CLIPBOARD" | "EMAIL" }) => {
  await trackClientEvent("Market Data Search Shared", {
    mode: event.mode,
  });
};

export const trackOnboardingStepperClick = async (event: { fromStep: OnboardingStep; toStep: OnboardingStep }) => {
  await trackClientEvent("Onboarding", {
    fromStep: event.fromStep,
    toStep: event.toStep,
    actionType: "STEPPER_CLICK",
  });
};

export const trackSalaryConverterSearched = async (event: {
  amount: number;
  fromLocation: EmployeeLocationWithCountry;
  toLocation: EmployeeLocationWithCountry;
}) => {
  await trackClientEvent("Salary Converter Searched", {
    amount: event.amount,
    fromLocationId: event.fromLocation.id,
    toLocationId: event.toLocation.id,
  });
};

export const trackPeoplePageSalaryBandsCTAClicked = async (event: {
  userId: number;
  companyId: number;
  cta: "upgrade" | "activate";
}) => {
  await trackClientEvent("People Page Salary Bands CTA Clicked", {
    userId: event.userId,
    companyId: event.companyId,
    cta: event.cta,
  });
};

export const trackSalaryBandLevelsStructured = async (event: { salaryGridId: number }) => {
  await trackClientEvent("Salary Bands Levels Structured", {
    salaryGridId: event.salaryGridId,
  });
};

export const trackSalaryBandJobsStructured = async (event: { salaryGridId: number }) => {
  await trackClientEvent("Salary Bands Jobs Structured", {
    salaryGridId: event.salaryGridId,
  });
};

export const trackSalaryBandLocationsStructured = async (event: { salaryGridId: number }) => {
  await trackClientEvent("Salary Bands Locations Structured", {
    salaryGridId: event.salaryGridId,
  });
};

export const trackSalaryBandLevelsMapped = async (event: { salaryGridId: number }) => {
  await trackClientEvent("Salary Bands Levels Mapped", {
    salaryGridId: event.salaryGridId,
  });
};

export const trackSalaryBandJobsMapped = async (event: { salaryGridId: number }) => {
  await trackClientEvent("Salary Bands Jobs Mapped", {
    salaryGridId: event.salaryGridId,
  });
};

export const trackSalaryBandLocationsMapped = async (event: { salaryGridId: number }) => {
  await trackClientEvent("Salary Bands Locations Mapped", {
    salaryGridId: event.salaryGridId,
  });
};

export const trackSalaryBandLevelsBenchmarked = async (event: { salaryGridId: number }) => {
  await trackClientEvent("Salary Bands Levels Benchmarked", {
    salaryGridId: event.salaryGridId,
  });
};

export const trackSalaryBandInviteNewUserClicked = async (event: { salaryGridId: number }) => {
  await trackClientEvent("Salary Bands Invite New User Clicked", {
    salaryGridId: event.salaryGridId,
  });
};

export const trackSalaryRangeWidthEdited = async (event: { salaryRangeId: number }) => {
  await trackClientEvent("Salary Range Width Edited", {
    salaryRangeId: event.salaryRangeId,
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const trackSelectorError = async (event: { name: string; error: Error }) => {
  await trackClientEvent("Selector error", {
    name: event.name,
    url: window.location.href,
    error: event.error.message ?? event.error.toString(),
  });
};

export const trackCommandPaletteOpened = async () => {
  await trackClientEvent("Command Palette Opened", {});
};

export const trackCommandPaletteClosed = async () => {
  await trackClientEvent("Command Palette Closed", {});
};

export const trackCommandPaletteSearch = async (event: { context: string; query: string }) => {
  await trackClientEvent("Command Palette Search", {
    context: event.context,
    query: event.query,
  });
};

export const trackCommandPaletteSelect = async (event: { keywords: string[] }) => {
  await trackClientEvent("Command Palette Select", {
    keywords: event.keywords,
  });
};

export const trackSalaryBandVersionsHistoryOpened = async (event: { salaryGridId: number }) => {
  await trackClientEvent("Salary Grid Versions History Opened", {
    salaryGridId: event.salaryGridId,
  });
};

export const trackSalaryBandsCompared = async (event: { salaryBandId: number; comparedSalaryBandId: number }) => {
  await trackClientEvent("Salary Bands Compared", event);
};

export const trackGenderPayGapChartHowToOpen = async (event: { chart: UnadjustedChartId | AdjustedChartId }) => {
  await trackClientEvent("Gender Pay Gap Chart How To Open", {
    chart: event.chart,
  });
};

export const trackGenderPayGapChartSeen = async (event: { chart: string }) => {
  await trackClientEvent("Gender Pay Gap Chart Seen", {
    chart: event.chart,
  });
};

export const trackEmployeeFormDrawerOpened = async () => {
  await trackClientEvent("EmployeeForm Drawer Opened", {});
};

export const trackEmployeeFormSubmitted = async (event: { changesCount: number }) => {
  await trackClientEvent("EmployeeForm Submitted", {
    changesCount: event.changesCount,
  });
};

export const trackEmployeeFormSubmissionResult = async (event: { success: boolean; errorMessage?: string }) => {
  await trackClientEvent("EmployeeForm Submission Result Received", event);
};

export const trackSalaryPeriodChanged = async (event: { salaryPeriod: CompensationReviewSalaryPeriod }) => {
  await trackClientEvent("Salary Period Changed", {
    salaryPeriod: event.salaryPeriod,
  });
};
