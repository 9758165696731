import {
  Balance,
  BugReport,
  GroupAddOutlined,
  Groups,
  HelpOutline,
  LocalPoliceOutlined,
  MultipleStopOutlined,
  OpenInNew,
  PeopleAltOutlined,
  PriceChangeOutlined,
  SettingsOutlined,
  VerticalAlignBottomOutlined,
  WaterfallChart,
} from "@mui/icons-material";
import { Stack, type SvgIconProps, Typography } from "@mui/material";
import classNames from "classnames";
import { Command } from "cmdk";
import copyToClipboard from "copy-to-clipboard";
import { type Route } from "nextjs-routes";
import React, { type ComponentType, useMemo, useState } from "react";
import { match } from "ts-pattern";
import { CommandItem } from "~/components/command-palette/CommandItem";
import { useCommandPalette } from "~/components/command-palette/CommandPaletteContext";
import { filterCommandPalette } from "~/components/command-palette/FilterCommandPalette";
import { EmployeePicture } from "~/components/employee/EmployeePicture";
import { CompensationReview } from "~/components/ui/core/icons/CompensationReviewIcon";
import { config } from "~/config";
import { useAlerts } from "~/hooks/useAlerts";
import { formatAppMetadataForCsm, useAppMetadataForCsm } from "~/hooks/useAppMetadataForCsm";
import { useOpenLink } from "~/hooks/useOpenLink";
import { usePermissions } from "~/hooks/usePermissions";
import { useSession } from "~/hooks/useSession";
import { useSubscriptions } from "~/hooks/useSubscriptions";
import { trackCommandPaletteSearch } from "~/lib/external/segment/web/events";
import { useI18n } from "~/lib/i18n/useI18n";
import { chain, compact } from "~/lib/lodash";
import { isClient } from "~/lib/ssr";
import { assertNotNil, getFiguresCompanyId } from "~/lib/utils";
import { useFetchCommandPaletteCommandsQuery } from "~/pages/api/command-palette/fetch-command-palette-commands";
import { useNotifyMetadataSupportMutation } from "~/pages/api/dev/notify-metadata-support";
import { formatPaletteCommandType, PaletteCommandType } from "~/services/command-palette/commandPaletteType";
import { formatExternalEmployeeName } from "~/services/external-employee";
import { formatSalaryBandName } from "~/services/salary-bands/helpers/formatSalaryBandName";

export const RootCommandPalette: React.FC = () => {
  const { t } = useI18n();
  const [search, setSearch] = useState("");
  const { showPalette, closePalette, togglePalette, selectedExternalEmployee, setSelectedExternalEmployee } =
    useCommandPalette();
  const { commands, isFetching } = useCommands({ search });
  const { openLink, isMetaPressed } = useOpenLink();
  const { user } = useSession();
  const { role } = usePermissions();

  const scrollTopListTop = () => {
    setTimeout(() => {
      document.querySelector("[cmdk-list]")?.scrollTo(0, 0);
    });
  };

  return (
    <Command.Dialog
      loop
      open={showPalette && !selectedExternalEmployee}
      onOpenChange={togglePalette}
      shouldFilter={false}
    >
      <Stack direction="row" alignItems="center" className="-mx-2 border-b border-primary-300 px-2 pb-2" spacing={2}>
        <Command.Input
          value={search}
          className="-mb-0.5 flex-1 p-2"
          placeholder={t("components.command-palette.root-command-palette.placeholder")}
          onValueChange={(value) => {
            setSearch(value);
            scrollTopListTop();
            void trackCommandPaletteSearch({ context: "root", query: value });
          }}
        />
        {isFetching && <Spinner className="absolute right-4 top-5" />}
        {!isFetching && <Shortcut className="absolute right-4 top-5" />}
      </Stack>

      <Command.List className="hide-scrollbar">
        <Command.Empty>{t("components.command-palette.no-results-found")}</Command.Empty>

        {commands.map((commands) => {
          const type = assertNotNil(commands[0]).type;
          const { group, label } = formatPaletteCommandType(t, type);

          return (
            <Command.Group key={type} heading={group}>
              {commands.map((command) =>
                match(command)
                  .with({ type: PaletteCommandType.NAVIGATION }, (command) => (
                    <CommandItem
                      key={command.name}
                      keywords={command.keywords}
                      Icon={command.icon}
                      label={label}
                      onSelect={() => {
                        openLink(command.to);
                        closePalette();
                      }}
                    >
                      <Typography variant="subtitle2">{command.name}</Typography>
                      {isMetaPressed && <OpenInNew className="text-sm text-gray-400" />}
                    </CommandItem>
                  ))
                  .with({ type: PaletteCommandType.EMPLOYEE }, (externalEmployee) => (
                    <CommandItem
                      key={externalEmployee.id}
                      keywords={command.keywords}
                      label={role.isSuperAdmin ? `${label} #${externalEmployee.id}` : label}
                      onSelect={() => {
                        setSelectedExternalEmployee(externalEmployee);
                      }}
                    >
                      <Stack alignItems="center" direction="row" spacing={3}>
                        <EmployeePicture
                          className="shrink-0"
                          picture={externalEmployee.picture}
                          placeholderId={externalEmployee.id}
                          pictureUrl={externalEmployee.userPermissions?.user?.profilePictureUrl}
                        />
                        <Typography variant="subtitle2" noWrap>
                          {formatExternalEmployeeName(externalEmployee)}
                        </Typography>
                      </Stack>
                    </CommandItem>
                  ))
                  .with({ type: PaletteCommandType.SALARY_BAND }, (salaryBand) => (
                    <CommandItem
                      key={salaryBand.id}
                      keywords={command.keywords}
                      label={label}
                      Icon={(props) => <WaterfallChart {...props} style={{ rotate: "90deg" }} />}
                      onSelect={() => {
                        if (!user?.company.defaultSalaryGridId) return;

                        openLink({
                          pathname: "/salary-bands/[gridId]/bands/[bandId]",
                          query: { gridId: `${user.company.defaultSalaryGridId}`, bandId: `${salaryBand.id}` },
                        });
                        closePalette();
                      }}
                    >
                      <Typography variant="subtitle2" noWrap>
                        {formatSalaryBandName(salaryBand)}
                      </Typography>
                    </CommandItem>
                  ))
                  .with({ type: PaletteCommandType.HELP_CENTER }, (article) => (
                    <CommandItem
                      key={article.name}
                      keywords={command.keywords}
                      label={label}
                      Icon={HelpOutline}
                      onSelect={() => {
                        openLink(article.url, { forceNewTab: true });
                        closePalette();
                      }}
                    >
                      <Typography variant="subtitle2">{article.name}</Typography>
                      <OpenInNew className="text-sm text-gray-400" />
                    </CommandItem>
                  ))
                  .with({ type: PaletteCommandType.ADVANCED_ACTIONS }, (action) => (
                    <CommandItem
                      key={action.name}
                      keywords={command.keywords}
                      label={label}
                      Icon={BugReport}
                      onSelect={() => {
                        action.run();
                        closePalette();
                      }}
                    >
                      <Typography variant="subtitle2">{action.name}</Typography>
                    </CommandItem>
                  ))
                  .exhaustive()
              )}
            </Command.Group>
          );
        })}
      </Command.List>
    </Command.Dialog>
  );
};

const useCommands = (params: { search: string }) => {
  const { showPalette } = useCommandPalette();

  const navigationCommands = useNavigationCommands();
  const advancedActionCommands = useAdvancedActionCommands();

  const { data: serverCommands, isFetching } = useFetchCommandPaletteCommandsQuery({
    options: {
      enabled: showPalette,
      staleTime: 360000,
    },
  });

  const availableCommands = useMemo(() => {
    const { externalEmployees = [], salaryBands = [], articles = [] } = serverCommands ?? {};

    return [
      ...navigationCommands.map((command) => ({ ...command, type: PaletteCommandType.NAVIGATION })),
      ...externalEmployees.map((command) => ({ ...command, type: PaletteCommandType.EMPLOYEE })),
      ...salaryBands.map((command) => ({ ...command, type: PaletteCommandType.SALARY_BAND })),
      ...articles.map((command) => ({ ...command, type: PaletteCommandType.HELP_CENTER })),
      ...advancedActionCommands.map((command) => ({ ...command, type: PaletteCommandType.ADVANCED_ACTIONS })),
    ];
  }, [serverCommands, navigationCommands]);

  const commands = useMemo(() => {
    return chain(availableCommands)
      .map((command) => ({
        ...command,
        score: filterCommandPalette(params.search, command.keywords),
      }))
      .filter((command) => command.score > 0.5)
      .orderBy((command) => command.score, "desc")
      .slice(0, 100)
      .groupBy((command) => command.type)
      .values()
      .value();
  }, [availableCommands, params.search]);

  return {
    commands,
    isFetching,
  };
};

const useNavigationCommands = () => {
  const { user } = useSession();
  const { t } = useI18n();
  const hasSalaryBands = !!user?.company.defaultSalaryGridId;
  const { subscriptions } = useSubscriptions();
  const { permissions, role } = usePermissions();

  type Command = {
    name: string;
    icon: ComponentType<SvgIconProps>;
    to: Route;
  };

  const routes: Command[] = [];

  if (subscriptions.CAN_ACCESS_BENCHMARK && permissions.canAccessMarketData) {
    routes.push({ name: t("components.layout.navbar.market-data"), icon: Balance, to: { pathname: "/market-data" } });
  }

  if (permissions.canAccessMarketDataPeopleDashboard || permissions.canAccessMarketDataPeopleDashboard) {
    routes.push({
      name: t("components.layout.navbar.people"),
      icon: Groups,
      to: { pathname: hasSalaryBands ? "/people/salary-bands" : "/people/market-data" },
    });
  }

  if (subscriptions.CAN_ACCESS_COMPENSATION_REVIEW) {
    routes.push({
      name: t("common.compensation-review"),
      icon: CompensationReview,
      to: { pathname: "/compensation-review" },
    });
  }

  const accountRouteName = (name: string) => `${t("components.layout.navbar.account")} · ${name}`;

  if (role.isHr || permissions.canAccessIntegrations) {
    routes.push({
      name: accountRouteName(t("components.layout.navbar.employees")),
      icon: GroupAddOutlined,
      to: { pathname: "/account/integrations", query: { tab: "imported-employees" } },
    });
  }

  if (permissions.canAccessRawData) {
    routes.push({
      name: accountRouteName(t("components.layout.navbar.mapping")),
      icon: MultipleStopOutlined,
      to: { pathname: "/account/mapping" },
    });
  }

  if (permissions.canAccessIntegrations) {
    routes.push({
      name: accountRouteName(t("components.layout.navbar.integrations")),
      icon: VerticalAlignBottomOutlined,
      to: { pathname: "/account/integrations" },
    });
  }

  if (permissions.canAccessAccount) {
    routes.push({
      name: accountRouteName(t("components.layout.navbar.users")),
      icon: PeopleAltOutlined,
      to: { pathname: "/account/users" },
    });
  }

  if (permissions.canAccessSettings) {
    routes.push(
      {
        name: accountRouteName(t("components.layout.navbar.compensation-policy")),
        icon: PriceChangeOutlined,
        to: { pathname: "/account/compensation-policy" },
      },
      {
        name: accountRouteName(t("components.layout.navbar.settings")),
        icon: SettingsOutlined,
        to: { pathname: "/account/settings" },
      }
    );

    if (permissions.canAccessPermissionsCenter) {
      routes.push({
        name: accountRouteName(t("components.layout.navbar.permissions-center")),
        icon: LocalPoliceOutlined,
        to: { pathname: "/account/permissions-center" },
      });
    }
  }

  return routes.map((route) => ({
    ...route,
    keywords: [t("enum.palette-command-type.navigation-group"), route.name],
  }));
};

const useAdvancedActionCommands = () => {
  const { user } = useSession();
  const { triggerAlert } = useAlerts();
  const { t } = useI18n();
  const metadataForCsm = useAppMetadataForCsm({ user });
  const notifyMetadataSupportMutation = useNotifyMetadataSupportMutation({
    successMessage: t("components.command-palette.metadata-sent-successfully"),
  });

  if (!metadataForCsm || !user) {
    return [];
  }

  const isFiguresUser = user.companyId === getFiguresCompanyId() || user.isSuperAdmin;
  const metadataPayload = formatAppMetadataForCsm(metadataForCsm);

  return compact([
    config.app.isLocal && {
      keywords: ["bug", "linear", "action", "Print page metadata to console"],
      type: PaletteCommandType.ADVANCED_ACTIONS,
      name: "Print page metadata to console",
      run: () => {
        window.console.log(metadataPayload);

        triggerAlert({
          type: "success",
          message: "Metadata printed to console",
        });
      },
    },
    isFiguresUser && {
      keywords: ["bug", "linear", "action", "Copy page metadata to clipboard"],
      type: PaletteCommandType.ADVANCED_ACTIONS,
      name: "Copy page metadata to clipboard",
      run: () => {
        copyToClipboard(metadataPayload);

        triggerAlert({
          type: "success",
          message: "Metadata copied to clipboard",
        });
      },
    },
    {
      keywords: ["bug", "linear", "action", t("components.command-palette.send-metadata-support")],
      type: PaletteCommandType.ADVANCED_ACTIONS,
      name: t("components.command-palette.send-metadata-support"),
      run: async () => notifyMetadataSupportMutation.mutateAsync(metadataForCsm),
    },
  ]);
};

const Spinner: React.FC<{ className: string }> = ({ className }) => (
  <svg
    className={classNames({
      [className]: true,
      "h-4 w-4 animate-spin text-secondary-500": true,
    })}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    />
  </svg>
);

const Shortcut: React.FC<{ className: string }> = ({ className }) => {
  const symbol = isClient && navigator.platform === "MacIntel" ? "⌘" : "ctrl";

  return (
    <Stack
      direction="row"
      spacing={1}
      className={classNames({
        [className]: true,
        "hidden select-none text-xs text-gray-700 md:flex": true,
      })}
    >
      <span className="flex h-5 w-5 items-center justify-center rounded bg-gray-100 p-px">{symbol}</span>
      <span className="flex h-5 w-5 items-center justify-center rounded bg-gray-100 p-px">K</span>
    </Stack>
  );
};
