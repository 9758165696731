import { Card, Stack, Typography } from "@mui/material";
import { type Currency, ExternalRemunerationType } from "@prisma/client";
import classNames from "classnames";
import React from "react";
import { FteTooltip } from "~/components/compensation-review/FteTooltip";
import { value } from "~/components/helpers";
import { CardHeader } from "~/components/ui/core/CardHeader";
import { useMoney } from "~/hooks/useMoney";
import { formatAdditionalFieldValue } from "~/lib/additionalField";
import { DateFormats } from "~/lib/dates";
import { useI18n } from "~/lib/i18n/useI18n";
import { chain, compact } from "~/lib/lodash";
import { formatPercent } from "~/lib/math";
import { type GetAllAdditionalFieldsResult } from "~/services/additional-field/getAllAdditionalFields";
import { formatExternalEmployeeName } from "~/services/external-employee";
import { type ExternalEmployeePanelSelect } from "~/services/external-employee/fetchExternalEmployeeForPanel";
import { formatGender } from "~/services/external-employee/gender";

type Props = {
  externalEmployee: ExternalEmployeePanelSelect;
  additionalFields: GetAllAdditionalFieldsResult;
};

export const ExternalEmployeeOverview: React.FC<Props> = ({ externalEmployee, additionalFields }) => {
  const { t, formatDate } = useI18n();

  const location = value(() => {
    if (!externalEmployee.location) return;

    if (externalEmployee.location.country)
      return `${externalEmployee.location.name}, ${externalEmployee.location.country.name}`;

    return externalEmployee.location.name;
  });

  const mappedAdditionalFields = compact(
    externalEmployee.additionalFieldValues.map((value) => {
      const field = additionalFields.find((field) => field.id === value.additionalFieldId);
      if (!field) return null;

      return { label: field.name, value: formatAdditionalFieldValue(value, field.nature) };
    })
  );

  return (
    <Stack spacing={4}>
      <OverviewCard title={t("components.external-employee-panel.overview.basics")}>
        <OverviewItem
          label={t("components.external-employee-panel.overview.name")}
          value={formatExternalEmployeeName(externalEmployee)}
        />
        <OverviewItem
          label={t("components.external-employee-panel.overview.job-title")}
          value={externalEmployee.job?.name}
        />
        <OverviewItem label={t("components.external-employee-panel.overview.location")} value={location} />
        <OverviewItem
          label={t("components.external-employee-panel.overview.gender")}
          value={externalEmployee.gender ? formatGender(t, externalEmployee.gender) : undefined}
        />
      </OverviewCard>

      <OverviewCard title={t("components.external-employee-panel.overview.compensation")}>
        <Stack direction="row" spacing={4}>
          <Typography variant="subtitle1" className="w-48 shrink-0">
            {t("components.external-employee-panel.overview.base-salary")}
          </Typography>
        </Stack>
        {externalEmployee.remunerationItems
          .filter((item) => item.nature.mappedType === ExternalRemunerationType.FIXED_SALARY)
          .map((item, index) => (
            <CompensationItem
              titleSecondaryColor={true}
              key={`base-salary-${index}`}
              fieldName="base-salary"
              label={
                item.date
                  ? t("components.external-employee-panel.overview.since", {
                      date: formatDate(item.date, DateFormats.FULL_DATE),
                    })
                  : t("components.external-employee-panel.overview.today")
              }
              value={item.amount * (externalEmployee.fteDivider ?? 1)}
              currency={externalEmployee.currency}
              fteDivider={externalEmployee.fteDivider}
            />
          ))}
        {externalEmployee.remunerationItems.filter(
          (item) => item.nature.mappedType === ExternalRemunerationType.FIXED_SALARY
        ).length === 0 && (
          <Typography variant="subtitle1" color="text.secondary">
            -
          </Typography>
        )}
        {externalEmployee.remunerationItems.filter(
          (item) => item.nature.mappedType === ExternalRemunerationType.VARIABLE_BONUS
        ).length > 0 ? (
          <CompensationItem
            label={t("components.external-employee-panel.overview.on-target-bonus")}
            fieldName="on-target-bonus"
            currency={externalEmployee.currency}
            fteDivider={externalEmployee.fteDivider}
            value={chain(externalEmployee.remunerationItems)
              .filter((item) => item.nature.mappedType === ExternalRemunerationType.VARIABLE_BONUS)
              .sumBy((item) => item.amount * (externalEmployee.fteDivider ?? 1))
              .value()}
          />
        ) : (
          <Typography variant="subtitle1" color="text.secondary">
            -
          </Typography>
        )}

        {externalEmployee.remunerationItems.filter(
          (item) => item.nature.mappedType === ExternalRemunerationType.FIXED_BONUS
        ).length > 0 ? (
          <CompensationItem
            label={t("components.external-employee-panel.overview.other-bonuses")}
            fieldName="other-bonuses"
            currency={externalEmployee.currency}
            fteDivider={externalEmployee.fteDivider}
            value={chain(externalEmployee.remunerationItems)
              .filter((item) => item.nature.mappedType === ExternalRemunerationType.FIXED_BONUS)
              .sumBy((item) => item.amount * (externalEmployee.fteDivider ?? 1))
              .value()}
          />
        ) : (
          <Typography variant="subtitle1" color="text.secondary">
            -
          </Typography>
        )}

        <CompensationItem
          label={t("components.external-employee-panel.overview.total-cash")}
          fieldName="total-cash"
          fteDivider={externalEmployee.fteDivider}
          value={chain(externalEmployee.remunerationItems)
            .filter(
              (item) =>
                item.nature.mappedType === ExternalRemunerationType.VARIABLE_BONUS ||
                item.nature.mappedType === ExternalRemunerationType.FIXED_BONUS ||
                item.nature.mappedType === ExternalRemunerationType.FIXED_SALARY
            )
            .sumBy((item) => item.amount * (externalEmployee.fteDivider ?? 1))
            .value()}
          currency={externalEmployee.currency}
        />
      </OverviewCard>

      <OverviewCard title={t("components.external-employee-panel.overview.work")}>
        <OverviewItem
          label={t("components.external-employee-panel.overview.hire-date")}
          value={externalEmployee.hireDate ? formatDate(externalEmployee.hireDate, DateFormats.FULL_DATE) : undefined}
        />
        <OverviewItem
          label={t("components.external-employee-panel.overview.manager")}
          value={externalEmployee.manager ? formatExternalEmployeeName(externalEmployee.manager) : undefined}
        />
        <OverviewItem
          label={t("components.external-employee-panel.overview.business-unit")}
          value={externalEmployee.businessUnit ?? undefined}
        />
        {externalEmployee.fteDivider && (
          <OverviewItem
            label={t("components.external-employee-panel.overview.working-time")}
            value={formatPercent(externalEmployee.fteDivider)}
          />
        )}
      </OverviewCard>

      {mappedAdditionalFields.length > 0 && (
        <OverviewCard title={t("components.external-employee-panel.overview.additional-fields")}>
          {mappedAdditionalFields.map((field) => {
            return <OverviewItem key={field.label} label={field.label} value={field.value} />;
          })}
        </OverviewCard>
      )}
    </Stack>
  );
};

const OverviewCard: React.FC<React.PropsWithChildren<{ title: string }>> = ({ title, children }) => {
  return (
    <Card className="w-full">
      <CardHeader title={title} />
      <Stack className="pt-4" spacing={4}>
        {children}
      </Stack>
    </Card>
  );
};

const OverviewItem: React.FC<{
  label: string;
  fieldName?: string;
  value: string | number | JSX.Element | undefined;
  titleSecondaryColor?: boolean;
}> = ({ label, fieldName, value, titleSecondaryColor = false }) => {
  return (
    <Stack direction="row" spacing={4}>
      <Typography
        variant="subtitle1"
        color={titleSecondaryColor ? "text.secondary" : ""}
        className="w-48 shrink-0"
        data-field={fieldName}
      >
        {label}
      </Typography>
      <Typography variant="subtitle1">{value ?? "-"}</Typography>
    </Stack>
  );
};

const CompensationItem: React.FC<{
  label: string;
  fieldName?: string;
  titleSecondaryColor?: boolean;
  currency: Currency;
  fteDivider: number | null;
  value: number;
}> = ({ label, fieldName, value, currency, fteDivider, titleSecondaryColor = false }) => {
  const money = useMoney(currency);

  return (
    <OverviewItem
      label={label}
      fieldName={fieldName}
      titleSecondaryColor={titleSecondaryColor}
      value={
        <FteTooltip fteDivider={fteDivider} value={value} currency={currency}>
          <Typography
            variant="subtitle1"
            className={classNames("text-right", {
              "underline decoration-gray-400 decoration-dotted underline-offset-4":
                fteDivider !== null && fteDivider !== 1,
            })}
          >
            {money.format(value, { roundTo: 1 })}
          </Typography>
        </FteTooltip>
      }
    />
  );
};
